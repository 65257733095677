import React from 'react';
import {connect} from 'react-redux';
import {  Col, Container, Form, Table, Row, Button } from 'react-bootstrap';


import QueryString from "query-string";


import { uploadFileResource, saveResourceToCloud, cleanResourceCache, requestReUploadResource, putResourceVideoThumbnail, v2} from '../../store/actions/resource'; 
import ReactPlayer from 'react-player';



function mapStateToProps(){
    return {}
}

const mapDispatchToProps = {
    uploadFileResource, saveResourceToCloud, cleanResourceCache, requestReUploadResource, putResourceVideoThumbnail,

    
    uploadVideoResourceV2: v2.uploadVideoResource,
}


class VideoResource extends React.Component{
    state = {
        name: {p720: "", p360: "", p480: ""},
        file: {p720: null, p360: null, p480: null, v2: null},
        uploading: {p720: false, p360: false, p480: false, v2: null},
        progress: { p360: 0, p720: 0, p480: 0, v2: 0},
        msg: {p720: "", p360: "", p480: "", v2: ""},
        randomNumber: 0,
    }

    componentDidMount(){
        const {resource,getSignedLinkOfResource} = this.props
        let randomNumber = Math.floor(Math.random() * 100000000)
        while (randomNumber === this.state.randomNumber) randomNumber = Math.floor(Math.random() * 100000000)
        this.setState({randomNumber })
        if(resource.p360){
            if(resource.p360url){
                const expired = ( Number(QueryString.parse(resource.p360url).Expires)*1000 - Date.now() ) < 1000;
                if(expired) getSignedLinkOfResource(resource.id, 360);
            }else{
                getSignedLinkOfResource(resource.id, 360);
            }

        }

        if(resource.p480){
            if(resource.p480url){
                const expired = ( Number(QueryString.parse(resource.p480url).Expires)*1000 - Date.now() ) < 1000;
                if(expired) getSignedLinkOfResource(resource.id, 480);
            }else{
                getSignedLinkOfResource(resource.id, 480);
            }

        }

        if(resource.p720){
            if(resource.p720url){
                const expired = ( Number(QueryString.parse(resource.p720url).Expires)*1000 - Date.now() ) < 1000;
                if(expired) getSignedLinkOfResource(resource.id, 720);
            }else{
                getSignedLinkOfResource(resource.id, 720);
            }
        }
    }

    handleChange = (e) => {
        let {name, value, type} = e.target
        const stateChanges = {name: {...this.state.name}, file: {...this.state.file}}
        stateChanges.name[name] = value
        stateChanges.file[name] = e.target.files[0]
        this.setState(stateChanges, ()=>this.handleUploadFile(name))
    }

    handleChangeV2 = (e) => {
        let {name, value, type} = e.target
        const stateChanges = {name: {...this.state.name}, file: {...this.state.file}}
        stateChanges.name[name] = value
        stateChanges.file[name] = e.target.files[0]
        this.setState(stateChanges)
    }

    handleUploadFileV2 = async (name) => {
        name = "v2"
        let fileData = new FormData()

        fileData.append(
            "resource",
            this.state.file[name],
            this.state.name[name]
        )
        const id = this.props.resource.id
        const onProgress = (p, m) => {
            let {progress, msg, uploading} = this.state

            this.setState({
                progress: {...progress, [name]: p},
                msg: {...msg, [name]: m},
                uploading: {...uploading, [name]: true},
            })
        }

        await this.props.uploadVideoResourceV2(id, fileData, onProgress)

        let randomNumber = Math.floor(Math.random() * 100000000)
        while (randomNumber === this.state.randomNumber) randomNumber = Math.floor(Math.random() * 100000000)

        this.setState({
            uploading: {...this.state.uploading, [name]: false},
            randomNumber
        })
    }

    handleUploadFile = async(name) =>{
        if(name === "v2") return this.handleUploadFileV2(name)
        let fileData = new FormData()

        fileData.append(
            "resource",
            this.state.file[name],
            this.state.name[name]
        )
        const id = this.props.resource.id
        const onProgress = (p, m) => {
            let {progress, msg, uploading} = this.state

            this.setState({
                progress: {...progress, [name]: p},
                msg: {...msg, [name]: m},
                uploading: {...uploading, [name]: true},
            })
        }
        const resolution = Number(name.substring(1))

        if(this.props.resource[name] === true){
            await this.props.requestReUploadResource(id, "video", onProgress, resolution)
        }

        await this.props.uploadFileResource(id, fileData, "video", onProgress, resolution)
        await this.props.saveResourceToCloud(id, "video", onProgress, resolution)
        await this.props.cleanResourceCache(id, "video", onProgress, resolution)


        this.setState({
            uploading: {...this.state.uploading, [name]: false},
        })
    }

    handleUploadThumbnail = async(e) =>{

        const thumbnail = e.target.files[0]

        let fileData = new FormData()

        fileData.append(
            "thumbnail",
            thumbnail,
            thumbnail.name
        )

        const id = this.props.resource.id
        
        await this.props.putResourceVideoThumbnail(id, fileData)
        console.log("Thumbnail Completed");


    }

    renderThumbnail = () => {
        const {resource} = this.props

        return <Row>
            <Col>
                <h4>Thumbnail</h4>
                <Form.File 
                    name="thumbnail"
                    accept=".jpg, .png" 
                    value={""}
                    onChange={this.handleUploadThumbnail}
                />
            </Col>
            <Col>
                {
                    resource.thumbnail  && 
                    <div className="resource-preview">
                        <img src={resource.thumbnail} alt="thumbnail" srcset="" height="100%" width="100%"/>
                    </div>
                }
            </Col>
        </Row>
    }

    renderVideo = (resolution) => {
        const {resource} = this.props
        const {progress, uploading, msg} = this.state

        return <Row>
            <Col>
                <h4>{resolution}p</h4>
                <Form.File 
                    name={`p${resolution}`}
                    accept=".mp4" 
                    value={this.state.name[`p${resolution}`]}
                    onChange={this.handleChange}
                />
                <h6>{uploading[`p${resolution}`] &&  <span> {progress[`p${resolution}`]}% {msg[`p${resolution}`]}  </span> }</h6>
            </Col>
            <Col>
                {
                    resource[`p${resolution}`] && resource[`p${resolution}url`] && 
                    <div className="resource-preview">
                        <ReactPlayer
                            key={this.state.randomNumber}
                            className = "resource-preview__player"
                            url={ resource[`p${resolution}url`] } 
                            width='100%'
                            height='100%'
                            controls
                        />
                    </div>
                }
            </Col>
        </Row>
    }

    renderVideoV2 = (resolution) => {
        const {resource} = this.props
        const {progress, uploading, msg} = this.state
        const urls = []
        // if( resource.urls && resource.urls[720]) urls.push(`${resource.urls[720]}?n=${this.state.randomNumber}`)
        // if( resource.urls && resource.urls[480]) urls.push(`${resource.urls[480]}?n=${this.state.randomNumber}`)
        if( resource.urls && resource.urls[360]) urls.push(`${resource.urls[360]}?n=${this.state.randomNumber}`)
        return <Row>
            <Col>
                <h4>Video</h4>
                <Form.File 
                    name={`${resolution}`}
                    accept=".mp4" 
                    value={this.state.name[`p${resolution}`]}
                    onChange={this.handleChangeV2}
                />
                {
                    uploading.v2 ?
                    <h6>{<span> {progress.v2}% {msg.v2}  </span> }</h6> :
                    <Button onClick={this.handleUploadFileV2.bind(this, "v2")}>Upload</Button>
                }
            </Col>
            <Col>
                Number {this.state.randomNumber}

                {
                    urls.length && 
                    <div className="resource-preview">
                        <ReactPlayer
                            className = "resource-preview__player"
                            url={ urls  } 
                            width='100%'
                            height='100%'
                            controls
                        />
                    </div>
                }
            </Col>
        </Row>
    }

    render(){
        const {resource} = this.props
        const uploadTime = resource._timestamp && (new Date(resource._timestamp));

        return <div className="video-resource">
        <h4>Video Information</h4>
        <Table>
            <tbody>
                <tr>
                    <td>ID</td>
                    <td> {resource.id} </td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td> {resource.name} </td>
                </tr>
                <tr>
                    <td>Extension</td>
                    <td> {resource.ext} </td>
                </tr>
                <tr>
                    <td>Type</td>
                    <td> {resource.type} </td>
                </tr>
                <tr>
                    <td>Size</td>
                    <td> {resource.size} Bytes </td>
                </tr>
                <tr>
                    <td>Uploaded on</td>
                    <td> {uploadTime && uploadTime.toLocaleString()} </td>
                </tr>
                <tr>
                    <td>Status Code</td>
                    <td> {resource.status} </td>
                </tr>
            </tbody>
        </Table>
        <Container>
            {this.renderThumbnail()}
            {this.renderVideoV2("v2")}
            {/* {this.renderVideo(360)}
            {this.renderVideo(480)}
            {this.renderVideo(720)} */}
        </Container>
        
    </div>
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(VideoResource);