import React, { Fragment } from 'react';
import QueryString from "query-string";
import { Button } from 'react-bootstrap';
import {connect} from 'react-redux';

import ResourcePreview from '../../components/resourcePreview';
import CourseModel from '../../models/course';
import NewCourseAssetModal from './NewCourseAssetModal';

import { getResource, getSignedLinkOfResource } from '../../store/actions/resource';
import { putCourseMetadata } from '../../store/actions/course';


function mapStateToProps({course, resource}){
    const { courses, levels, weeks, days, assets } = course
    return {
        courses, levels, weeks, days, assets, resource
    }
}

const mapDispatchToProps = {
    putCourseMetadata, getResource, getSignedLinkOfResource
}

class CourseBanner extends React.Component{

    state = {
        change1x1: false,
        change16x9: false,
        changeIntro: false,
    }

    get course() {return CourseModel.from(this.props.course);}

    componentDidMount(){

    }
    
    handleToggleBannerForm = (name, e)=>{
        e && e.preventDefault()
        this.setState({
            [name]: !this.state[name]
        })
    }

    handleChange1x1=async(title, rid)=>{
        const course = this.course;
        course.bannerRid1x1 = rid;
        const success = await this.props.putCourseMetadata(course);
        if(success) alert("Successfully attached the course metadata.")
        else alert("Some error occured")
    }

    handleChange16x9=async(title, rid)=>{
        const course = this.course;
        course.bannerRid16x9 = rid;
        const success = await this.props.putCourseMetadata(course);
        if(success) alert("Successfully attached the course metadata.")
        else alert("Some error occured")
    }

    handleChangeIntro=async(title, rid)=>{
        const course = this.course;
        course.introRid = rid;
        const success = await this.props.putCourseMetadata(course);
        if(success) alert("Successfully attached the course metadata.")
        else alert("Some error occured")
    }

    render1x1banner(){
        const course = this.course
        return <div className="course-banner__1x1" >
            <div class="course-banner__change" onClick={this.handleToggleBannerForm.bind(this, "change1x1")}>Change</div>
            <NewCourseAssetModal
                show={this.state.change1x1}
                onHide={this.handleToggleBannerForm.bind(this, "change1x1")} 
                onSubmit={this.handleChange1x1}
                type="image"
            />
            {this.course.bannerRid1x1 && <ResourcePreview key={course.bannerRid1x1} rid={course.bannerRid1x1} />}
        </div>
    }


    render16x9banner(){
        const course = this.course
        return <div className="course-banner__16x9" >
            <div class="course-banner__change" onClick={this.handleToggleBannerForm.bind(this, "change16x9")}>Change</div>
            <NewCourseAssetModal
                
                show={this.state.change16x9}
                onHide={this.handleToggleBannerForm.bind(this, "change16x9")} 
                onSubmit={this.handleChange16x9}
                type="image"
            />
            {this.course.bannerRid16x9 && <ResourcePreview key={course.bannerRid16x9} rid={course.bannerRid16x9} />}
        </div>
    }

    renderIntro(){
        const course = this.course
        return <div className="course-banner__intro" >
            <div class="course-banner__change" onClick={this.handleToggleBannerForm.bind(this, "changeIntro")}>Change</div>
            <NewCourseAssetModal
                show={this.state.changeIntro}
                onHide={this.handleToggleBannerForm.bind(this, "changeIntro")} 
                onSubmit={this.handleChangeIntro}
                type="video"
            />
            {this.course.introRid && <ResourcePreview key={course.introRid} rid={course.introRid} />}
        </div>
    }

    render(){
        return <div className="course-banner">
            <h3>Banner and Intro</h3>
            <div className="course-banner__resources">
                {this.render1x1banner()}
                {this.render16x9banner()}
                {this.renderIntro()}
            </div>
        </div>
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(CourseBanner)