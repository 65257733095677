import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/index.scss';

import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter as Router} from 'react-router-dom';
import { Provider } from 'react-redux';

import AuthenTicatedComponent from './hocs/AuthenticatedComponent'
import { configureStore } from './store';
import { checkValidityOfToken } from './store/actions/user';



const store = configureStore()

const CheckAppStart = () => {
  const [waiting, setWaiting] = useState(true)
  useEffect(() =>{
    checkValidityOfToken()(store.dispatch)
    .then(()=>setWaiting(_ => false))
    .catch(()=>setWaiting(_ => false))
  }, [])
  console.log("It is waiting", waiting)
  if(waiting){
    return <div className="loader"></div>
  }
  return <AuthenTicatedComponent component={App} />

}


ReactDOM.render(
  <Provider store={store}>
    <Router>
      <CheckAppStart/>
    </Router>
  </Provider>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
