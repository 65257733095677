import * as CustomButtons from './Button'
import {Form as CustomForm} from './Form'
import CustomSelectInput from './Select'
import CustomSearchInput from './Search'

export const FlatButton = CustomButtons.FlatButton;
export const Form = CustomForm;
export const SearchInput = CustomSearchInput;
export const SelectInput = CustomSelectInput;

export const DatePicker = ({name="", value="", label, onChange}) => {
    return <div className="input  date-input">
        <div className=" input__label date-input__label">{label}</div>
        <div className="input__field  date-input__field">
            <input type="date" name={name} value={value} onChange={onChange}/>
        </div>
    </div>
}

export const DateTimePicker = ({name="", value="", label, onChange}) => {
    return <div className="input date-input">
        <div className="input__label date-input__label">{label}</div>
        <div className="input__field  date-input__field">
            <input type="datetime-local" name={name} value={value} onChange={onChange}/>
        </div>
    </div>
}


export const TimePicker = ({name="", value="", label, onChange}) => {
    return <div className="input date-input">
        <div className="input__label date-input__label">{label}</div>
        <div className="input__field date-input__field">
            <input type="time" name={name} value={value} onChange={onChange}/>
        </div>
    </div>
}

export const RadioInput = ({label, children, name, options=[], onChange, value}) => {
    return <div key={value} className="input radio-input">
        <div className="input__label radio-input__label">{label}</div>
        <div className="radio-input__options">
            {
                options.map(
                    op => <RadioOption 
                        key={op.value} 
                        name={name} 
                        value={op.value} 
                        label={op.label} 
                        onChange={onChange} 
                        checked={value === op.value}
                    />
                )
            }
            {children}
        </div>
    </div>
}

export const RadioOption = ({name, value, label, onChange, checked= false}) => {
    return <div className="input radio-input__option-item" >
        <input className="radio-input__option-field" name={name} type="radio" value={value} onChange={onChange} checked={checked}/>
        <div className="radio-input__option-label">{label}</div>
    </div>
}


export const TextInput = ({name="", value="", label, onChange, type="text"}) => {
    return <div className="input text-input">
        <div className="input__label text-input__label">{label}</div>
        <div className="input__field text-input__field">
            <input type={type} name={name} value={value} onChange={onChange}/>
        </div>
    </div>
}