import React from 'react';


const SearchResult = ({list, onClickItem}: {list: Array<String>, onClickItem: any }) => {
    return <div className="search-result">
        <div>
            {
                list.map((v, i) => <div key={i} 
                    className="search-result__item" 
                    onClick={(e) => {
                        e.preventDefault();
                        onClickItem(i);
                    }}
                >
                    {v}
                </div>)
            }
        </div>
    </div>
    
}



export default SearchResult