import React from 'react';
import  Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {Link} from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';

class ApplicationNavbar extends React.Component{

    state = {
        active: 0
    }

    render(){
        return <Navbar collapseOnSelect expand="lg" bg="light" variant="light" >
            <Navbar.Brand href="/">3Q Abacus</Navbar.Brand>
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
                <Nav >
                    <Nav.Item href="/">
                        <Nav.Link eventKey={0} as={Link} to="/" >
                            Home
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item href="/assets"  >
                        <Nav.Link eventKey={1} as={Link} to="/assets">
                            Assets
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item href="/events" >
                        <Nav.Link eventKey={2} as={Link} to ="/events">
                            Events
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item href="/courses" >
                        <Nav.Link eventKey={3} as={Link} to="/courses">
                            Courses
                        </Nav.Link>
                    </Nav.Item>
                    <Nav.Item href="/resource" >
                        <Nav.Link eventKey={5} as={Link} to="/resource">
                            Resources
                        </Nav.Link>
                    </Nav.Item>

                    <NavDropdown title="Academy" id="basic-nav-dropdown" >
                        <NavDropdown.Item href="/academy/new/instructor" >
                            <Nav.Link eventKey={6} as={Link} to="/academy/new/instructor" >
                                Add new instructor
                            </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/academy/instructor/list">
                            <Nav.Link eventKey={6} as={Link} to="/academy/instructor/list">
                                Instructor List
                            </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Divider />
                        <NavDropdown.Item href="/academy/new/online-class">
                            <Nav.Link eventKey={6} as={Link} to="/academy/new/online-class">
                                Schedule new online class
                            </Nav.Link>
                        </NavDropdown.Item>
                        <NavDropdown.Item href="/academy/online-class/list">
                            <Nav.Link eventKey={6} as={Link} to="/academy/online-class/list">
                                Online Class List
                            </Nav.Link>
                        </NavDropdown.Item>
                    </NavDropdown>

                    <Nav.Item href="/academy" >
                        
                    </Nav.Item>
                </Nav>
            </Navbar.Collapse>
        </Navbar>
    }
}

export default ApplicationNavbar;