import React from 'react';
import { Button, Col, Form, Modal } from 'react-bootstrap';


class NewCourseForm extends React.Component{
    state={
        title: "",
        hasLevel: false,
        shortDuration: false,
        description: ""
    }
    handleChange = (e) => {
        const {name, value, type, checked} = e.target;
        if(type==="checkbox"){
            this.setState({
                [name]: checked
            })
        }else{
            this.setState({
                [name]: value
            });
        }
    }

    handleSubmitCreateNewCourse = (e) => {
        e.preventDefault();
        console.log(this.props.onSubmit({...this.state}))
        this.props.onHide()
    }

    render(){
        
        const {title, hasLevel, shortDuration, description} = this.state
        return <div className="new-course-form">
            <Modal centered size="lg" show={this.props.show} onHide={this.props.onHide}>
                <Modal.Header closeButton>
                    <Modal.Title>New Course Form</Modal.Title>
                </Modal.Header>
                <Form onSubmit={this.handleSubmitCreateNewCourse}>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Name of the Course</Form.Label>
                            <Form.Control name="title" value={title} onChange={this.handleChange}/>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Course Duration Information</Form.Label>
                            <Form.Row>
                                <Col>
                                    <Form.Check
                                        type="checkbox"
                                        label = "The course has levels."
                                        checked={hasLevel}
                                        name="hasLevel"
                                        onChange={this.handleChange}
                                    />
                                    <Form.Check
                                        type="checkbox"
                                        label = "The course is of short duration."
                                        name="shortDuration"
                                        checked={shortDuration}
                                        onChange={this.handleChange}
                                    />
                                </Col>
                            </Form.Row>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Description</Form.Label>
                            <Form.Control as="textarea" name="description" value={description} onChange={this.handleChange}/>
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="warning" onClick={this.props.onHide}>Cancel</Button>
                        <Button variant="success" type="submit">Create</Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            
        </div>
    }
}

export default NewCourseForm;