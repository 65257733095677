import Axios from 'axios';
import React from 'react';

class Home extends React.Component{
    state={
        file: null,
        filename: ""
    }
    onChange = (e) => {
        e.preventDefault()
        debugger
        this.setState({
            file: e.target.files[0],
            filename: e.target.files[0].name
        })
    }

    upload = async (e) => {
        const url = "https://qabacus-dev.appspot.com.storage.googleapis.com/testfile?GoogleAccessId=firebase-adminsdk-ep6l5%40qabacus-dev.iam.gserviceaccount.com&Expires=1605807338&Signature=CfstclVy7LijyertRBBsbtoF8IfPR%2FQCAl9GwZ18Ti5MzgnQt%2B31gpC33ax38e9fKK2J2eyFaaCORtMyV013iFFqTnjHwPmXJJ%2F8a%2FqFvS6O9JCtxkq%2BjAxd36qqZ%2BscMJapPVguClxCUCGmuLmcWvOR17BETj4Jf%2FKj27GmoeOP224tW%2ByTw78IZ9MY9JN%2BfnZYgFubplKpoaJgUkEG5GWCLFw%2FIPHIGyFIVObKba3ocfYwJcisi9Qmvg0awQQfTjmiru1XhjxrJtCocqqS6%2BuLx00R7LhKSPLMGc3d9PI1JZHm1l9H%2BrrH5k0HnMTZYy4FPD69D5BMYY5tW70EFQ%3D%3D"

        const res =  await Axios.post(url, this.state.file, {
            headers: {
                "Content-Type": this.state.file.type
            } 
        })
        console.log(res)
    }
    render(){
        return <div className="home">
            <center>
                <h2>Welcome</h2>
                <h3>You are visiting to 3QAbacus Admin Panel</h3>
            </center>
        </div>
    }
}

export default Home;