export const Form = ({className, children, content, actions, title}) => {
    className = className? `${className} form` : className;
    return <div className={className}>
        <div className="form-title">{title}</div>
        <div className="form-content">
            {content}
        </div>
        <div className="form-actions">{actions}</div>
        {children}
    </div>
}