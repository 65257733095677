import { LOAD_RESOURCES, DELETE_RESOURCE, ADD_RESOURCE } from "../../actionTypes"
import { GET_RESOURCE, GET_RESOURCE_SIGNED_LINK } from "../../actionTypes"

import ReosurceModel from '../../../models/resource'
import { api } from "../../../services/apiService"
import { mapArrayToMap } from "../../../services/utils"
import ResourceModel from "../../../models/resource"

import * as v2Resource from './v2'

export const v2 = v2Resource



export const uploadFileResource = (id, fileData, type, onProgress, resolution) => {
    return async dispatch => {
        let url = `/resource/v1/${id}/${type}`
        if(type.indexOf("video") === 0) url = `${url}?resolution=${resolution}`
        console.log(url)

        const res2 = await api.post(url, fileData, {
            onUploadProgress: (progress) => {
                const percentage = Math.floor(1000 * progress.loaded / progress.total) / 10
                onProgress(percentage, "Uploading")
            }
        })
        const step2 = res2.data
        if(step2.success){
            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel(step2.resource)
            })
            // cb(step2.msg)
            onProgress(100, "Saving to cloud")
        }
        return step2.success
    }
}

export const saveResourceToCloud = (id, type, onProgress, resolution) => {
    return async dispatch => {

        let url = `/resource/v1/${id}/${type}`
        if(type.indexOf("video") === 0) url = `${url}?resolution=${resolution}`
        console.log(url)

        // step3 request server to save the file into a file server
        const res3 = await api.post(url)
        const step3 = res3.data
        if(step3.success){

            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel(step3.resource)
            })
            // cb(step3.msg)
            onProgress(100, "Cleaning cache")
        }
        return step3.success
        
    }
    
}

export const cleanResourceCache = (id, type, onProgress, resolution) => {
    return async dispatch => {

        // step2 upload file to the server
        let url = `/resource/v1/${id}/${type}`
        if(type.indexOf("video") === 0) url = `${url}?resolution=${resolution}`
        console.log(url)

        // step4 finally request the server to delete the resource from cache
        const res4 = await api.post(url)
        const step4 = res4.data
        if(step4.success){
            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel(step4.resource)
            })
            // cb(step4.msg)
            onProgress(100, "Completed")
        }
        return step4.success

    }
}

export const requestReUploadResource = (id, type, onProgress, resolution) => {
    return async dispatch => {

        // step2 upload file to the server
        let url = `/resource/v1/${id}/${type}`
        if(type.indexOf("video") === 0) url = `${url}?resolution=${resolution}`
        console.log(url)
        onProgress(0, "ReUpload Request")


        // step4 finally request the server to delete the resource from cache
        const res4 = await api.post(url)
        const step4 = res4.data
        if(step4.success){
            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel(step4.resource)
            })
            // cb(step4.msg)
            onProgress(0, "ReUpload Request Success")
        }
        return step4.success

    }
}






export const deleteResource = (id) => {
    return async dispatch => {
        const res = await api.delete('/resource/v1/'+id)
        const {success} = res.data
        if(success){
            dispatch({
                type: DELETE_RESOURCE,
                id
            })
        }
    }
}

export const getQuery = (searchString) => {
    return async dispatch =>{
        const res = await api.get("/resource/v1/query?q=" + searchString)
        const {success, list} = res.data
        if(success){
            dispatch({
                type: LOAD_RESOURCES,
                resources: mapArrayToMap( list.map( v => new ReosurceModel(v) ) )
            })
        }
    }
}

export const getResource = (id) => {
    return async dispatch => {
        const res = await api.get(`/resource/v1/${id}`);
        const {success, resource, msg} = res.data
        if(success){
            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel(resource)
            })
        }else{
            console.log(msg)
        }
    }
}

export const getSignedLinkOfResource= (id, resolution) => {
    return async dispatch => {
        console.log(resolution)
        let url = `/resource/v1/${id}/link`
        if(resolution){
            url = `${url}?resolution=${resolution}`
        }
        const res = await api.get(url);
        const {success, resource, msg} = res.data
        if(success){
            dispatch({
                type: GET_RESOURCE_SIGNED_LINK,
                resource: new ResourceModel(resource)
            })
        }else{
            console.log(msg)
        }
    }
}

export const loadResources = () => {
    return async dispatch =>{
        const res = await api.get("/resource/v1/list")
        const {success, list} = res.data
        if(success){
            dispatch({
                type: LOAD_RESOURCES,
                resources: mapArrayToMap( list.map( v => new ReosurceModel(v) ) )
            })

        }
    }
}


export const postResourceMetadata = (metadata) => {
    return async dispatch => {
        const res = await api.post('/resource/v1', metadata)
        const {success, resource} = res.data
        if(success){
            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel(resource)
            })
            return resource.id
        }
    }
}

export const putResourceMetadata = (id, metadata) => {
    return async dispatch => {
        const res = await api.put(`/resource/v1/${id}`, metadata)
        const {success, resource} = res.data
        if(success){
            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel(resource)
            })
        }
    }
}

export const putResourceVideoThumbnail = (id, thumbnail) => {
    return async dispatch => {
        const res = await api.put(`/resource/v1/${id}/video/thumbnail`, thumbnail)
        const {success, resource} = res.data
        if(success){
            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel({ id, ...resource })
            })
        }
    }
}