import React, { Fragment } from 'react';
import {connect} from 'react-redux';

import { FlatButton, Form, TextInput } from '../../components/Inputs';
import * as instructorActions from '../../store/actions/academy/instructor'

function mapStateToProps (state){
    return state
}

const mapDispatchToProps = {
    addNewInstructor: instructorActions.addNew
}


class NewInstructorForm extends React.Component{
    state={
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: ""
    }

    handleChange = (e) => {
        if(e){
            e.preventDefault()
            const {name, value} = e.target;
            this.setState({
                [name]: value
            })
        }

    }

    handleCancel = (e) => {
        e && e.preventDefault()
        console.log("Cancel")
    }

    handlesSubmit = async (e) => {
        e && e.preventDefault()

        const paylaoad = { ...this.state }
        await this.props.addNewInstructor(paylaoad)
        this.props.history.replace('/academy/instructor/list')
    }

    render(){
        const {firstName, lastName, email, phoneNumber} = this.state
        return <div className="new-instrutor">
            <Form 
                title="New Instructor Form"
                content= {
                    <Fragment>
                        <div  className="new-instructor__name">
                            <TextInput label="First Name" name="firstName" value={firstName} onChange={this.handleChange}/>
                            <TextInput label="Last Name" name="lastName" value={lastName} onChange={this.handleChange}/>
                        </div>
                        <div  className="new-instructor__email">
                            <TextInput label="Email" name="email" value={email} onChange={this.handleChange}/>
                        </div>
                        <div className="new-instructor__phone-number">
                            <TextInput label="Phone Number" name="phoneNumber" value={phoneNumber} onChange={this.handleChange}/>
                        </div>
                    </Fragment>
                }
                actions={
                    <Fragment>
                        <FlatButton title="Cancel" onClick={this.handleCancel}/>
                        <FlatButton title="Submit" onClick={this.handlesSubmit}/>
                    </Fragment>
                }
            />
        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewInstructorForm);