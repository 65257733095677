import { LOAD_RESOURCES, DELETE_RESOURCE, ADD_RESOURCE } from "../../actionTypes"
import { GET_RESOURCE, GET_RESOURCE_SIGNED_LINK } from "../../actionTypes"

import { api } from "../../../services/apiService"

import ResourceModel from "../../../models/resource"


export const craeteVideoResource = (metadata) => {
    return async dispatch => {
        const res = await api.post('/resource/v2', metadata)
        const {success, resource} = res.data
        if(success){
            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel(resource)
            })
            return resource.id
        }
    }
}

export const uploadVideoResource = (id, fileData, onProgress) => {
    return async dispatch => {
        let url = `/resource/v2/${id}/video`
        const res = await api.post(url, fileData, {
            onUploadProgress: (progress) => {
                const percentage = Math.floor(1000 * progress.loaded / progress.total) / 10
                onProgress(percentage, "Uploading")
            }
        })
        if(res.data.success){
            dispatch({
                type: GET_RESOURCE,
                resource: new ResourceModel(res.data.resource)
            })
            onProgress(100, "Saving to cloud")
        }
        return res.data.success
    }
}
