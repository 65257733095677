import CourseModel, { CourseAssetModel, CourseDayModel, CourseLevelModel, CourseWeekModel } from '../../models/course'
import {ADD_COURSE_LEVEL, ADD_COURSE_WEEK, ADD_COURSE_DAY, ADD_COURSE_DAY_ASSET, GET_RESOURCE, DELETE_COURSE} from '../actionTypes'
import {CREATE_COURSE } from '../actionTypes'
import {GET_COURSE, GET_COURSE_LEVEL, GET_COURSE_WEEK, GET_COURSE_DAY} from '../actionTypes'
import {LOAD_ALL_COURSES} from '../actionTypes';
import {UPDATE_COURSE_DAY, UPDATE_COURSE} from '../actionTypes';


import {api} from '../../services/apiService'
import {mapArrayToMap} from '../../services/utils'





export const addLevelToCourse = (cid, title) => {
    
    return async dispatch => {
        const res = await api.post(`/course/${cid}/level`, {title});
        const {success, level} = res.data;
        if(success){
            dispatch({
                type: ADD_COURSE_LEVEL,
                level: new CourseLevelModel(level)
            })
        }
    }
}

export const addWeekToLevel = (cid, lid, title) => {
    
    return async dispatch =>{
        const res = await api.post(`/course/${cid}/level/${lid}/week`, {title});
        const {success, week} = res.data;
        if(success){
            dispatch({
                type: ADD_COURSE_WEEK,
                week: new CourseWeekModel(week)
            })
        }
    }
}

export const addAssetToWeek = () => {
    //TODO needs to be implemeted
    window.alert("It is yet not implemented")
}

export const addDayToWeek = (cid, lid, wid, title) => {
    return async dispatch =>{
        const res = await api.post(`/course/${cid}/level/${lid}/week/${wid}/day`, {title});
        const {success, day} = res.data;
        if(success){
            dispatch({
                type: ADD_COURSE_DAY,
                day: new CourseDayModel(day)
            })
        }
    }
}

export const addAssetToDay = (cid, lid, wid, did, title, rid) => {
    return async dispatch => {
        const res = await api.post(`/course/${cid}/level/${lid}/week/${wid}/day/${did}/asset`, {title, rid})
        const {success, asset, resource} = res.data
        if(success){
            dispatch({
                type: GET_RESOURCE,
                resource
            })

            dispatch({
                type: ADD_COURSE_DAY_ASSET,
                asset: new CourseAssetModel({id: asset._id, ...asset})
            })
        }

    }
}

export const createCourse = (newCourse) => {
    return async (dispatch) => {
        const res = await api.post('/course', newCourse);
        const {success, course} = res.data;
        if(success) {
            dispatch({
                type: CREATE_COURSE,
                course: new CourseModel(course)
            })
        }
    }
}

export const deleteCourse = (id) => {
    return async (dispatch) => {
        const res = await api.delete('/course/'+id);
        const {success} = res.data;
        if(success) {
            dispatch({
                type: DELETE_COURSE,
                id
            })
        }
    }
}


export const getCourseById = (cid) => {
    return async dispatch => {
        const res = await api.get(`/course/${cid}`)
        const {success, course, levels} = res.data
        if(success){
            const lids = levels.map(v => v._id)
            const courseModel = new CourseModel({id: course._id, ...course, levels: lids})
            const levelModels = levels.map(level => new CourseLevelModel({id: level._id, ...level}));
            dispatch({
                type: GET_COURSE,
                course: courseModel,
                levels: mapArrayToMap(levelModels)
            })
        }
    }
}

export const getLevelById = (cid, lid) => {
    return async dispatch => {
        const res = await api.get(`/course/${cid}/level/${lid}`)
        const {success, level, weeks} = res.data
        if(success){
            const wids = weeks.map(week => week._id)
            const levelModel = new CourseLevelModel({id: level.id, ...level, weeks: wids})
            const weekModels = weeks.map(week => new CourseWeekModel({id: week._id, ...week}));
            dispatch({
                type: GET_COURSE_LEVEL,
                level: levelModel,
                weeks: mapArrayToMap(weekModels)
            })
        }
    }
}

export const getWeekById = (cid, lid, wid) => {
    return async dispatch => {
        const res = await api.get(`/course/${cid}/level/${lid}/week/${wid}`)
        const {success, week, days} = res.data
        if(success){
            const dids = days.map(day => day._id)
            const weekModel = new CourseWeekModel({id: week._id, ...week, days: dids})
            const dayModels = days.map(day => new CourseDayModel({id: day._id, ...day}));
            dispatch({
                type: GET_COURSE_WEEK,
                week: weekModel,
                days: mapArrayToMap(dayModels)
            })
        }
    }
}

export const getDayById = (cid, lid, wid, did) => {
    return async dispatch => {
        const res = await api.get(`/course/${cid}/level/${lid}/week/${wid}/day/${did}`)
        const {success, day, assets} = res.data
        if(success){
            const aids = assets.map(asset => asset._id)
            const dayModel = new CourseDayModel({id: day._id, ...day, assets: aids})
            const assetModels = assets.map(asset => new CourseAssetModel({id: asset._id, ...asset}));
            dispatch({
                type: GET_COURSE_DAY,
                day: dayModel,
                assets: mapArrayToMap(assetModels)
            })
        }
    }
}


export const loadAllCourses = () => {
    return async dispatch => {
        const res = await api.get("/course/list");
        const {success, courses} = res.data
        if(success){
            const list = courses.map(course => new CourseModel({id: course._id, ...course}));
            dispatch({
                type: LOAD_ALL_COURSES,
                list
            })
        }
    }
}

export const putCourseMetadata = (course)=>{
    return async dispatch => {
        const paylaod = {
            title: course.title, 
            highlights: course.highlights, 
            prerequisite: course.prerequisite,
            description: course.description, 
            cost: course.cost,
            bannerRid1x1: course.bannerRid1x1,
            bannerRid16x9: course.bannerRid16x9,
            introRid: course.introRid
        }
        const res = await api.put(`/course/${course.id}/meta`, paylaod)
        console.log(res.data)
        const {success, id, updatePayload} = res.data
        if(success && id === course._id){
            dispatch({
                type: UPDATE_COURSE,
                course
            })
        }
        return success;
    }
}

// export const putCourseAssetData = ({id, title, index, rid, _cid})=>{
//     return async dispatch => {
//         const paylaod = {title, index, rid}
//         const res = await api.put(`/course/${_cid}/asset/${id}`, paylaod)
//         console.log(res.data)
//         const {success} = res.data
//         if(success){
//             dispatch({
//                 type: UPDATE_ASSET,
//                 course
//             })
//         }
//         return success;
//     }
// }

export const publishCourse = (cid) => {
    return async dispatch => {
        const res = await api.post(`/course/${cid}/publish`);
        const {success} = res.data
        console.log(res.data)
        return success
    }
}