import React from 'react';
import ApplicationNavbar from '../components/navbar';
import { Route, Switch } from 'react-router-dom'; 
import Courses from './Courses';
import { Container } from 'react-bootstrap';
import CourseEdit from './course';
import Resource from './Resource';
import ResourceInfomormation from './resource/ResourceInformation';
import ApplicationFooter from '../components/Footer';
import Home from './Home';
import AcademyMain from './academy/Main';
import NewInstructorForm from './academy/NewInstructorForm';
import InstructorList from './academy/InstructorList';
import ScheduleOnlineClass from './academy/ScheduleOnlineClass';
import OnlineClassList from './academy/OnlineClassList';


const Assets = () => <div>Assets</div>
const Events = () => <div>Events</div>

const BackSoon = () => <h1>It will be back soon</h1>;


function App() {
  return (
    <div className="App">
      <ApplicationNavbar/>
      <Container>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/assets" component={Assets} />
          <Route exact path="/events" component={Events} />
          
          <Route exact path="/courses" component={Courses} />
          <Route exact path="/course/:id" component= {CourseEdit} />

          {/* <Route exact path="/courses" component={BackSoon} />
          <Route exact path="/course/:id" component= {BackSoon} /> */}

          <Route exact path="/resource" component={Resource} />
          <Route exact path="/resource/:id" component={ResourceInfomormation} />

          <Route exact path="/academy" component={AcademyMain} />
          <Route exact path="/academy/new/instructor" component={NewInstructorForm} />
          <Route exact path="/academy/instructor/list" component={InstructorList} />
          <Route exact path="/academy/new/online-class" component={ScheduleOnlineClass} />
          <Route exact path="/academy/online-class/list" component={OnlineClassList} />

        </Switch>
      </Container>
      <div style={{height: 50}}></div>
      <ApplicationFooter/>
    </div>
  );
}

export default App;
