import React from 'react';
import { connect } from 'react-redux';
import { Button, Form, Modal, Col, Spinner } from 'react-bootstrap';
import { uploadFileResource, postResourceMetadata, v2 } from '../../store/actions/resource'; 

function mapStateToProps(){
    return {}
}



const mapDispatchToProps = {
    uploadFileResource, postResourceMetadata,
    createVideoResourceV2: v2.craeteVideoResource,
}

class ResourceUpload extends React.Component{

    state={
        type: "",
        accept: "",
        resourceName: "",
        resource: null,
        name: "",
        processing: false,
        msg: "",
        completed: false
    }

    handleChange = (e) => {
        let {name, value, type} = e.target
        if(type === "file"){
            this.setState({
                resource: e.target.files[0]
            })
        }
        this.setState({
            [name]: value
        })
    }

    handleOnHide = (e) => {
        e && e.preventDefault();
        this.handleReset();
        if(this.props.onHide) this.props.onHide();
    }

    handleReset = () => {
        this.setState({
            type: "",
            accept: "",
            resourceName: "",
            resource: null,
            name: "",
            processing: false,
            msg: "",
            completed: false
        })
    }

    handleSelectFileType = (type, e) => {
        e.preventDefault();
        let accept = ""
        if(type === "video"){
            accept = ".mp4"
        }
        else if(type === "video/youtube"){
            accept = null
        }
        else if (type=== "image"){
            accept = ".jpg, .jpeg, .png"
        }
        else if(type === "pdf"){
            accept = ".pdf"
        }
        else if(type.indexOf("method") === 0){
            accept = null
        }else{
            // Invalid type.... dont do anything
            return;
        }
        this.setState({
            type, accept
        })
    }


    handleUpload = async (e) => {
        e.preventDefault();
        const {type, processing, completed} = this.state
        if(this.state.name.length < 3) {
            alert("Resource Name length should be greater than 3.")
            return;
        }
        if(processing) return;
        if(completed) return this.handleOnHide();
        this.setState({
            processing: true
        })
        if(type==="video"){
            return this.handleUploadVideoFile()
        }else if(type==="pdf"){
            // TODO handle pdf file upload
        }else if(type==="image"){
            return this.handleUploadImageFile()
        }else{
            const metadata = {
                type: this.state.type, 
                name: this.state.name,
            }
            const id = await this.props.postResourceMetadata(metadata)
            this.setState({
                msg: !id ? "Resource creation failed." : "Resource Created with id: " + id + ".",
                completed: true,
                processing: false
            })
        }
    }

    handleUploadImageFile = async() =>{
        
        const metadata = {
            type: "image/jpeg", 
            name: this.state.name, 
        }

        const id = await this.props.postResourceMetadata(metadata)

        this.setState({
            msg: "Upload process is finished...",
            completed: true,
            processing: false
        })
    }

    handleUploadVideoFile = async() =>{
        
        const metadata = {
            type: "video/mp4", 
            name: this.state.name, 
        }

        // const id = await this.props.postResourceMetadata(metadata)
        const id = await this.props.createVideoResourceV2(metadata)

        this.setState({
            msg: "Upload process is finished...",
            completed: true,
            processing: false
        })
    }


    renderResourceTypes=()=>{
        return <div className="resource-types">
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "video/youtube")}>Youtube Video</div>
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "video")}>Video</div>
            {/* <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "pdf")}>PDF</div> */}
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "image")}>Image</div>
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "method/abacus")}>Abacus Resource</div>
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "method/abacustonumber")}>Abacus to Number Resource</div>
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "method/fingers")}>Fingers Resource</div>
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "method/flashcard")}>Flashcard Resource</div>
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "method/jumbledictation")}>Jumble Dictation Resource</div>
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "method/mentalabacus")}>Mental Abacus Resource</div>
            <div className="resource-types__item" onClick={this.handleSelectFileType.bind(this, "method/numbertoabacus")}>Number To Abacus Resource</div>
        </div>
    }

    renderSelectFile = () =>{
        const {processing ,completed} = this.state
        if(processing) return <div>Processing</div>
        if(completed) return <div>Upload Completed...</div>
        return <div>
            <Form.Group>
                <Form.Row>
                    <Col>
                        <Form.Label>Resource Name</Form.Label>
                        <Form.Control 
                            type="text" name="name"
                            value={this.state.name}
                            onChange={this.handleChange}
                        />
                    </Col>
                </Form.Row>
                {/* <video controls src="https://qabacus-dev.appspot.com.storage.googleapis.com/822fa8cd-64a3-47e1-ac05-5b5adc0060df?GoogleAccessId=firebase-adminsdk-ep6l5%40qabacus-dev.iam.gserviceaccount.com&Expires=1605465378&Signature=cO5saMGq6nwKBg4LS0HL0yomzRF0MosSXnZbrRCpOudbAIKueoAmlS1iQBL37ivQIlGKI5u8sUB%2BmEMvT%2BWRQQ8uGTby0BkhYc10zdjx1ASlyxCOkc6bwjzdPckEobSM20ckS66T4YhIZAxPptziJ%2FvYJwx%2FlbKvFimaAsmnLIPbMMOwxWfudsRzDipzFmBTX9glHci54MBf%2Bdyygi3OSPSw5y2AQH7%2FX5WQ%2FyCoyozjBu0mTvpDpELNpTqLgE4mQ%2BtsSlCsYAa8Jhn7S89ysuFKeezfeXGOiWVjdi3%2Bsuy0gJoPAigCDOE3EvGTwEWMcTotp3%2B%2FZN7mvWjiiCzb4w%3D%3D" /> */}
            </Form.Group>
        </div>
    }

    render(){
        return <Modal show={this.props.show} onHide={this.handleOnHide}>
            <Modal.Header>
                <Modal.Title>
                    Resource Upload
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                { !this.state.type ? this.renderResourceTypes() : this.renderSelectFile()}
            </Modal.Body>
            <Modal.Footer>
                {this.state.msg}
                {
                    this.state.type && 
                    <Button onClick={this.handleUpload}>
                        {this.state.processing && <Spinner as="span" animation="border" size="sm"/>}
                        {this.state.completed ? "Okay" : (this.state.processing ? "Processing" : "Upload")}
                    </Button>
                }
            </Modal.Footer>
        </Modal>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceUpload);