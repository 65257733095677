import {Component} from 'react';
import {connect} from 'react-redux';

import { TextInput } from '../components/Inputs';
import { FlatButton } from '../components/Inputs/Button';
import {login} from '../store/actions/user'


function mapStateToProps({appState}){
    return {...appState.login}
}

const mapDispatchToProps = { login }

class LoginPage extends Component{
    state = {
        username: "",
        password: ""
    }

    componentDidMount(){
        this.setState({
            username: this.props.username,
            password: this.props.password
        })
    }

    handleChange=(e) => {
        e.preventDefault();
        const {name, value} = e.target
        this.setState({
            [name] : value
        })
    }


    handleLogin = (e) => {
        e.preventDefault()
        const {username, password} = this.state
        this.props.login(username, password)
    }

    render(){
        const {username, password} = this.state
        return <div className="login-page">
        
            <div>
                <div>
                    <TextInput label="Username" name="username" value={username} onChange={this.handleChange}/>
                </div>
                <div>
                    <TextInput type="password" name="password" label="Password" value={password} onChange={this.handleChange}/>
                </div>
                <div>
                    <FlatButton onClick={this.handleLogin}>Login</FlatButton>
                </div>
            </div>
        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)