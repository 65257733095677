
import React from 'react';
import {connect} from 'react-redux';
import ReactPlayer from 'react-player';
import QueryString from "query-string";


import { getResource, getSignedLinkOfResource } from '../store/actions/resource';

function mapStateToProps({ resource}){

    return {resource}
}

const mapDispatchToProps = {getResource, getSignedLinkOfResource}

class ResourcePreview extends React.Component{

    state = {
        resource: {}
    }

    async componentDidMount(){
        if(this.props.resource[this.props.rid]) this.state.resource = this.props.resource[this.props.rid];
        else{
            const s = await this.props.getResource(this.props.rid)
            console.log(this.props,  this.props.resource)
            this.state.resource = this.props.resource[this.props.rid];
            console.log(this.state.resource)
            if(this.state.resource){

                if(this.state.resource.signedUrl){
                    const expired = ( Number(QueryString.parse(this.state.resource.signedUrl).Expires)*1000 - Date.now() ) < 1000;
                    if(expired) await this.props.getSignedLinkOfResource(this.props.rid, this.props.resolution);
                }else{
                    await this.props.getSignedLinkOfResource(this.props.rid, this.props.resolution);
                }
            }
        }
        this.setState();
    }

    render(){
        if(this.state.resource)
            return  <Preview type={this.state.resource.type} url={this.state.resource.signedUrl} />
        return null
    }
}

const Preview = ({type, url}) => {

    if(!type || !url) return <div>Loading</div>
    if(type.indexOf("video") === 0){

        return <div className="resource-preview">
            <ReactPlayer 
                className = "resource-preview__player"
                url={url} 
                width='100%'
                height='100%'
                controls
            />
        </div>
    }
    else if(type.indexOf("image") === 0){
        return <div className="resource-preview">
            <img 
                height="100%"
                width="100%"
                src={url} alt="image"
            />
        </div>
    }
    return <div>Invalid Preview</div>
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourcePreview);