import { combineReducers } from 'redux';
import course from './course';
import resource from './resource';
import appState from './appState';
import * as academyReducer from './academy'

const rootReducer = combineReducers({ 
    course,
    resource,
    appState,
    instructor: academyReducer.instructor,
    onlineClass: academyReducer.onlineClass
});

export default rootReducer;