import React from 'react';
import {Table, Nav, Button, Modal} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';


import NewCourseForm from '../components/newCourseForm';
import {createCourse, loadAllCourses, deleteCourse} from '../store/actions/course'
import CourseModel from '../models/course';


function mapStateToProps({course}){
    const {courseStore, courses} = course
    return {courseStore, courses}
}



const mapActionsToProps = {createCourse, loadAllCourses, deleteCourse}



class Courses extends React.Component{
    state = {
        newCourseForm: false
    }
    componentDidMount(){
        this.props.loadAllCourses()
    }

    handleOpenNewCourseForm=()=>{
        this.setState({newCourseForm: !this.state.newCourseForm});
    }
    
    handleDeleteCourse=async(course, e)=>{
        await this.props.deleteCourse(course.id)
        alert("Request Completed")
    }
  

    renderCourseList = () => {
        const courseList = Object.values(this.props.courses);
        return courseList.map((c, i) => {
                const course = CourseModel.from(c)
                return  <tr key={course.id}>
                <td>{i+1}</td>
                <td>{course.title}</td>
                <td>{course.hasLevel? "true" : "false"}</td>
                <td>{course.shortDuration? "true" : "false"}</td>
                <td>
                    { Intl.DateTimeFormat("us").format(course.createdAt)}
                </td>
                <td>
                    <Button as={Link} to={`/course/${course.id}`} block>Edit</Button>
                    <Button block onClick={this.handleDeleteCourse.bind(this, course)}>Delete</Button>
                </td>
            </tr>;
        })
        
    }

    
    
    render(){
        return <div className="course-page">
            <h1>
                All Courses <Button variant="primary" onClick={this.handleOpenNewCourseForm}>Add New One</Button>
            </h1>
            <Table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Name</th>
                        <th>Has Levels</th>
                        <th>Short Duration</th>
                        <th>Created at</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderCourseList()}
                    {/* {this.renderCourseInList()} */}
                </tbody>
            </Table>
            <NewCourseForm show={this.state.newCourseForm} onHide={this.handleOpenNewCourseForm} onSubmit={this.props.createCourse}/>
        </div>
    }
}

export default connect(mapStateToProps, mapActionsToProps)(Courses);