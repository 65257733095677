import React from 'react';
import { Link } from 'react-router-dom';



export const Sidebar = (props:any) => {
    return <div className="sidebar">
            {props.children}
        </div>
}

export const SidebarItem = (props: {label: React.Component, to: string}) => {
    return <Link to={props.to} className="sidebar__item">{props.label}</Link>
}