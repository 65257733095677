import React from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { connect } from 'react-redux';

import CourseLevel from './CourseLevel';
import CourseWeek from './CourseWeek';
import CourseDay from './CourseDay';
import CourseAsset from './CourseAsset';
import NewCourseAssetModal from './NewCourseAssetModal';

import CourseModel, { CourseAssetModel, CourseDayModel, CourseLevelModel, CourseWeekModel } from '../../models/course'
import {addLevelToCourse, addWeekToLevel, addDayToWeek, addAssetToDay,
    getCourseById, getLevelById, getWeekById, getDayById} from '../../store/actions/course';
import { getResource } from '../../store/actions/resource';


function mapStateToProps({course, resource}){
    const { courses, levels, weeks, days, assets } = course
    return {
        courses, levels, weeks, days, assets, resource
    }
}

const mapDispatchToProps = {
    addLevelToCourse, addWeekToLevel, addDayToWeek, addAssetToDay,
    getCourseById, getLevelById, getWeekById, getDayById, getResource
}

class CourseContent extends React.Component{

    state = {
        selected: {level: null, week: null, day: null, asset: null},
        level: null,
        week: null,
        day: null,
        show_form: false,
    }

    get course(){
        const id = this.props.courseId
        const course = this.props.courses[id]
        return !course ? null : CourseModel.from(course)
    }

    

    handleAddHighlight = (e) => {
        e && e.preventDefault()
        const highlight = prompt("Enter text here");
        if(highlight){
            this.course.highlights(highlight)
        }
    }

    

    handleAddLevelToCourse = (e) => {
        e.preventDefault()
        const title = prompt("Level Title");
        if(title){
            this.props.addLevelToCourse(this.course.id, title);
        }
    }

    handleAddWeekToLevel = (e) => {
        e.preventDefault()
        const {selected} = this.state
        const course = this.course
        const level = this.props.levels[selected.level]
        const title = prompt("Week Title");
        if(title){
            this.props.addWeekToLevel(course.id, level.id, title);
        }

    }

    handleAddDayToWeek = (e) => {
        e.preventDefault()
        const {selected} = this.state
        const course = this.course
        const level = this.props.levels[selected.level]
        const week = this.props.weeks[selected.week]
        const title = prompt("Day Title");
        if(title){
            this.props.addDayToWeek(course.id, level.id, week.id, title)
        }
    }

    handleAddAssetToDay = async (title, rid) => {
        const {selected} = this.state
        const course = this.course
        const level = this.props.levels[ selected.level ]
        const week = this.props.weeks[ selected.week ]
        const day = this.props.days[ selected.day ]
        
        if(title && rid){
            try{
                await this.props.addAssetToDay(course.id, level.id, week.id, day.id, title, rid)
                return true
            }catch(err){
                console.log(err)
                return false
            }
        }else{
            alert("You need to give a valid title and select resource from list.")
            
            return false
        }
    }


    handleSelect = (id, colName, e) => {
        e.preventDefault();
        var {selected} = this.state
        const course = this.course

        if(colName === "level"){
            const level = CourseLevelModel.from(this.props.levels[id])
            for(let i=0; i < level.weeks.length; i++){
                if(!this.props.weeks[level.weeks[i]]){
                    this.props.getLevelById(course.id, level.id)
                    break;
                }
            }
            selected = {...selected, level: id, week: null, day: null}
        }else if(colName === "week"){
            const week = CourseWeekModel.from(this.props.weeks[id])
            for(let i=0; i < week.days.length; i++){
                if(!this.props.days[week.days[i]]){
                    this.props.getWeekById(course.id, selected.level, week.id)
                    break;
                }
            }
            selected= {...selected, week: id, day: null}
        }else if(colName === "day"){
            const day = CourseDayModel.from(this.props.days[id])
            for(let i=0; i < day.assets.length; i++){
                if(!this.props.assets[day.assets[i]]){
                    this.props.getDayById(course.id, selected.level, selected.week, day.id)
                    break;
                }
            }
            selected = {...selected, day: id}
        }else if(colName === "asset"){
            const asset = CourseAssetModel.from(this.props.assets[id])
            if(!this.props.resource[asset.rid]) this.props.getResource(asset.rid)
            selected = {...selected, asset: id}
        }
        this.setState({
            selected
        })
    }

    handleToggleShowForm = (e) => {
        e && e.preventDefault()
        this.setState({
            show_form: !this.state.show_form
        })
    }

    // !=======================================================================================================
    // !=======================================================================================================
    // !=======================================================================================================
    // !=======================================================================================================
    // !=======================================================================================================

    

    renderLevels=()=>{
        const { selected} = this.state
        const id = this.props.courseId
        const course = CourseModel.from(this.props.courses[id]) 
        let flag = false;
        return (
            <div className="course-structure__col" >
                <h4> Levels 
                    {
                        !course.hasLevel ? null : 
                        <Button className="inline" onClick={this.handleAddLevelToCourse} >
                            +
                        </Button>
                    }
                </h4>
                {
                    course.levels.map((id, i) => {
                        if(flag) return null;
                        if(!this.props.levels[id]){
                            flag = true;
                            this.props.getCourseById(this.props.courseId)
                            return <Spinner animation="border"/>;
                        }
                        const level = CourseLevelModel.from(this.props.levels[id])
                        const isActive = course.hasLevel && selected.level === level.id;
                        
                        return <div key={level.id} 
                                className= {`course-structure__item ${isActive? "course-structure__item--active" : "" }` }
                                onClick={this.handleSelect.bind(this, level.id, "level")}
                            >
                                {level.title}
                            </div>
                        }
                    )
                }
            </div>
        )
    }

    renderWeeks = () => {
        const {selected} = this.state
        if( selected.level === null ) return <div className="course-structure__col"></div>
        const course = this.course
        const level = this.props.levels[selected.level]
        if(!level) return <div className="course-structure__col"></div>;
        return <div className="course-structure__col">
            <h4>Weeks 
                {
                    course.shortDuration ? null : 
                    <Button className="inline" 
                        onClick = {this.handleAddWeekToLevel}
                    >
                        +
                    </Button>
                }
            </h4>
            <CourseLevel id={level.id} activeId={this.state.selected.week} onItemClick = {this.handleSelect}/>
        </div>
    }

    renderDays = () => {
        const {selected} = this.state
        if( selected.week === null ) return <div className="course-structure__col"></div>
        const week = this.props.weeks[selected.week]
        if(!week) return <div className="course-structure__col"></div>;
        return <div className="course-structure__col">
            <h4>Days
                {
                    <Button className="inline" 
                        onClick = {this.handleAddDayToWeek}
                    >
                        +
                    </Button>
                }
            </h4>
            <CourseWeek activeId={this.state.selected.day} id={week.id} onItemClick={this.handleSelect}/>
        </div>
    }

    renderDayAssets = () => {
        const {selected} = this.state
        if( selected.day === null ) return <div className="course-structure__col"></div>
        const day = this.props.days[selected.day]
        if(!day) return <div className="course-structure__col"></div>;
        return <div className="course-structure__col">
            <h4>Assets 
                {
                    <Button className="inline" 
                        onClick = {this.handleToggleShowForm}
                    >
                        +
                    </Button>
                }
            </h4>
            <CourseDay id={day.id} activeId={this.state.selected.asset} onItemClick={this.handleSelect}/>
        </div>
    }


    render(){
        const {selected} = this.state
        const id = this.props.courseId
        if( !this.props.courses[id] ) return <div>Loading</div>
        const course =  CourseModel.from(this.props.courses[id])
        return <div className="course-content">
            <h3>Course Structure</h3>
            <div className="course-structure">
                {this.renderLevels()}
                {this.renderWeeks()}
                {this.renderDays()}
                {this.renderDayAssets()}
            </div>
            {selected.asset && <CourseAsset id={selected.asset}/>}
            <NewCourseAssetModal async
                show={this.state.show_form}
                onHide={this.handleToggleShowForm} 
                onSubmit={this.handleAddAssetToDay}
            />
            

        </div>
    }

}

export default connect(mapStateToProps, mapDispatchToProps) (CourseContent);