import { ThemeConsumer } from "react-bootstrap/esm/ThemeProvider";

export class CourseAssetModel{
    static from(asset:CourseAssetModel){
        return new CourseAssetModel(asset)
    }
    id: string;
    rid: string;
    title: string;
    type: string;

    _cid: string;
    _lid: string; 
    _wid: string;
    _did: string;

    _timestamp: number;
    _createdBy:string;        
    _modifiedBy:string;
    
    constructor(props:{
        id:string, rid: string, title:string, type: string,
        _cid: string, _lid: string, _wid: string, _did: string,
        _timestamp: number, _createdBy:string, _modifiedBy:string,
    }){
        this.id = props.id;
        this.rid = props.rid;
        this.title = props.title;
        this.type = props.type;

        this._did = props._did;

        this._cid = props._cid;
        this._lid = props._lid;
        this._wid = props._wid;
        this._timestamp = props._timestamp;
  
        this._createdBy = props._createdBy;
        this._modifiedBy = props._modifiedBy;
    }
}


export class CourseDayModel{
    static from(course:CourseDayModel){
        return new CourseDayModel(course)
    }


    id: string;
    title: string;
    assets: Array<string>;
    _cid: string;
    _lid: string; 
    _wid: string; 
    _timestamp: number;
    _createdAt: number;
    _createdBy:string;        
    _modifiedBy:string;

    constructor(props:{
        id:string, title:string, assets: Array<string>,
        _cid: string, _lid: string, _wid: string,
        _timestamp: number, _createdAt: number, _createdBy:string, _modifiedBy:string,
    }){
        // this.id = id;
        this.id = props.id;
        this.title = props.title;
        this.assets = props.assets || [];
        
        this._cid = props._cid;
        this._wid = props._wid;
        this._lid = props._lid;
        this._timestamp = props._timestamp;
        this._createdAt = props._createdAt;
        this._createdBy = props._createdBy;
        this._modifiedBy = props._modifiedBy;
    }

    addAsset(asset: CourseAssetModel){
        this.assets.push(asset.id);
    }
}

export class CourseWeekModel{
    static from(course:CourseWeekModel){
        return new CourseWeekModel(course)
    }

    id: string;
    title: string;
    days: Array<string>;
    assets: Array<string>;
    _cid: string;
    _lid: string;
    _timestamp: number;
    _createdAt: number;
    _createdBy: string; 
    _modifiedBy: string;

    constructor(props:{
        id:string, title:string, days:Array<string>, assets: Array<string>,
        _cid: string, _lid: string, _timestamp: number,
        _createdAt: number, _createdBy: string, _modifiedBy: string,
    }){
        this.id = props.id;
        this.title = props.title;
        this.days = props.days || [];
        this.assets = props.assets || [];
        this._cid = props._cid;
        this._lid = props._lid;
        this._timestamp = props._timestamp;
        this._createdAt = props._createdAt;
        this._createdBy = props._createdBy;
        this._modifiedBy = props._modifiedBy;
    }

    addAsset(asset:CourseAssetModel){
        this.assets.push(asset.id);
    }
    
    addDay(day:CourseDayModel){
        this.days.push(day.id);
    }
}


export class CourseLevelModel{
    static from(course:CourseLevelModel){
        return new CourseLevelModel(course)
    }


    id: string;
    title: string;
    weeks: Array<string>;
    cid:string;
    timestamp: number;
    created_at: number;
    created_by: string; 
    modified_by: string;
    constructor(props:{
            id: string, title: string, weeks: Array<string>, 
            cid:string, timestamp: number, created_at: number, 
            created_by: string, modified_by: string
    }){
        this.id = props.id;
        this.title = props.title;
        this.weeks = props.weeks || [];
        this.cid = props.cid;
        this.timestamp = props.timestamp;
        this.created_at = props.created_at;
        this.created_by = props.created_by;
        this.modified_by = props.modified_by;
    }

    addWeek(week:CourseWeekModel){
        this.weeks.push(week.id);
    }

}



class CourseModel{
    static from(course:CourseModel){
        return new CourseModel(course)
    }


    id:string; 
    title:string; 
    hasLevel:boolean; 
    shortDuration:boolean; 
    description:string; 
    highlights: Array<string>;
    prerequisite: Array<string>;

    bannerRid1x1: string;
    bannerRid16x9: string;
    introRid: string;

    cost: number;
    currency: string;
    levels:Array<string>;

    _timestamp: number;
    _createdAt: number;
    _createdBy: string;
    _modifiedBy: string;
    _publishedOn: number;

    constructor(props:{
        id:string, title:string, hasLevel:boolean, shortDuration:boolean, description:string, 
        highlights: Array<string>, prerequisite: Array<string>
        bannerRid1x1: string, bannerRid16x9: string, introRid: string,
        cost: number, currency: string,
        levels:Array<string>, 
        _timestamp: number, _createdBy: string, _modifiedBy: string,
        _publishedOn: number
    }){
        this.id = props.id;
        this.title = props.title;
        this.hasLevel = props.hasLevel;
        this.shortDuration = props.shortDuration;
        this.description = props.description;
        this.highlights = props.highlights ? props.highlights : [];
        this.prerequisite = props.prerequisite ? props.prerequisite : [];

        this.bannerRid1x1 = props.bannerRid1x1 ? props.bannerRid1x1 : "";
        this.bannerRid16x9 = props.bannerRid16x9? props.bannerRid16x9 : "";
        this.introRid = props.introRid ? props.introRid : '';


        this.cost = props.cost;
        this.currency = props.currency;
        this.levels = props.levels;
        this._timestamp = props._timestamp;
        this._createdAt = props._timestamp;
        this._createdBy = props._createdBy;
        this._modifiedBy = props._modifiedBy;
        this._publishedOn = props._publishedOn;
    }

    get metadata(){
        return {
            id: this.id,
            title:this.title, highlights:this.highlights, description:this.description, cost: this.cost
        }
    }

    addLevel(level:CourseWeekModel){
        this.levels.push(level.id);
    }


}

export default CourseModel