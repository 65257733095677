import React, {useState} from 'react';
import { Spinner } from 'react-bootstrap';
import {connect} from "react-redux";
import { CourseDayModel, CourseAssetModel } from '../../models/course';

function mapStateToProps({course}){
    const {days, assets} = course
    return {
        days, assets
    };
}


const mapDispatchToProps = {}


// class CourseLevel extends React.Component{
const CourseDay = ({id, days, assets, activeId, onItemClick}) =>{
    if(!id) return <div className="course-week">Invalid ID</div>
    const day = days[id] && CourseDayModel.from(days[id])
    return <div className="course-day">
        {day.assets.map( aid => {
            const asset = assets[aid] && CourseAssetModel.from(assets[aid])
            const active = asset && activeId === asset.id;
            return <div key={aid} className={`course-structure__item ${active? "course-structure__item--active" : "" }`}
                onClick={(e) => onItemClick(asset.id, "asset", e)}>
                {!asset ? <Spinner animation="border"/> : asset.title}
            </div>
        })}
    </div>
}
// }

export default connect(mapStateToProps, mapDispatchToProps)(CourseDay);