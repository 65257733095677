import { CREATE_INSTRUCTOR, GET_INSTRUCTOR_LIST } from "../../actionTypes"

const initialInstructors = {}


const instructorReducer = (state = initialInstructors, action) => {
    switch(action.type){
        case CREATE_INSTRUCTOR:{
            const {instructor} = action
            return {...state, [instructor.id]: instructor}
        }
        case GET_INSTRUCTOR_LIST: {
            return {...state, ...action.list}
        }
        default: return state
    }
}

export default instructorReducer;
