import React from 'react';

function MethodSettings({min, max, step, label, name, value, onDecrease, onIncrese }){

    return <div className="method-settings">
        <div className="method-settings__label">{label}</div>
        <div className="method-settings__btn" onClick={ (e) => onDecrease(step, name, e)}>-</div>
        <div className="method-settings__value">{value}</div>
        <div className="method-settings__btn" onClick={(e) => onIncrese(step, name, e)}>+</div>
    </div>
}

export default MethodSettings;