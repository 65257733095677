import axios, {AxiosRequestConfig} from 'axios';

// const uri = "http://localhost:5000/api";
const uri = "/api";

const getConfig = (config?: AxiosRequestConfig) =>{
    const token = window.localStorage.getItem("token");
    if(token){
        if(config){
            if(config.headers){
                config = {...config, headers: { "Authorization": `Bearer ${token}`, ...config.headers }}
            }else{
                config = {...config, headers: { "Authorization": `Bearer ${token}` }}
            }
        }else{
            config= {headers: { "Authorization": `Bearer ${token}` }}
        }
    }
    return config
}

export const api = {
    get: (route: string, config?: AxiosRequestConfig) => {
        const url = `${uri}${route}`;
        
        config = getConfig(config);
        return axios.get(url, config)
    },
    post: (route: string, data:any, config?: AxiosRequestConfig)=>{
        const url = `${uri}${route}`;
        config = getConfig(config);
        return axios.post(url, data, config)
    },
    put: (route: string, data:any, config?: AxiosRequestConfig) =>{
        const url = `${uri}${route}`;
        config = getConfig(config);
        return axios.put(url, data, config)
    },
    delete: (route: String, config?: AxiosRequestConfig)=>{
        const url = `${uri}${route}`;
        config = getConfig(config);
        return axios.delete(url, config)
    }
}