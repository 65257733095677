import React from 'react';
import {connect} from 'react-redux'
import { Button, Form, Table } from 'react-bootstrap';

import QueryString from "query-string";

import ResourcePreview from '../../components/resourcePreview';
import { uploadFileResource, saveResourceToCloud, cleanResourceCache, requestReUploadResource, putResourceVideoThumbnail } from '../../store/actions/resource'; 

function mapStateToProps(){
    return {}
}

const mapDispatchToProps = {
    uploadFileResource, saveResourceToCloud, cleanResourceCache, requestReUploadResource, putResourceVideoThumbnail
}


class ImageResource extends React.Component{
    state = {
        name: "",
        file: null,
        progress: 0,
        msg: "",
        uploading: false,
    }
    async componentDidMount(){
        const {resource, getSignedLinkOfResource} = this.props
        if(resource.status === "upload-s2"){
            this.setState({
                uploading: true,
                progress: 100,
                msg: "Cleaning cloud cache for this resource"
            })

            await this.props.cleanResourceCache(resource.id, "image", (p, m)=>{
                this.setState({
                    uploading: true,
                    progress: p,
                    msg: m,
                })
            })
            this.setState({
                uploading: false,
                progress: 0,
                msg: "",
            })
        } 
        if(resource.signedUrl){
            const expired = ( Number(QueryString.parse(resource.signedUrl).Expires)*1000 - Date.now() ) < 1000;
            if(expired) getSignedLinkOfResource(resource.id);
        }else{
            getSignedLinkOfResource(resource.id);
        }
    }

    handleChange = (e) => {
        let { value} = e.target
        const stateChanges = {}
        stateChanges.name = value
        stateChanges.file = e.target.files[0]
        this.setState(stateChanges, 
            // ()=>this.handleUploadFile(name)
        )
    }

    handleUploadFile = async() =>{
        let fileData = new FormData()
        // let ext = this.state.file.name.split(".")
        // ext = ext[ext.length-1]
        fileData.append(
            "resource",
            this.state.file,
            this.state.name
        )
        const id = this.props.resource.id
        const onProgress = (p, m) => {
            this.setState({
                progress: p,
                msg: m,
                uploading: true,
            })
        }

        let success = true
        if(this.props.resource.status === "live"){
            success = await this.props.requestReUploadResource(id, "image", onProgress)
        }

        if(success) success = await this.props.uploadFileResource(id, fileData, "image", onProgress)
        if(success) success = await this.props.saveResourceToCloud(id, "image", onProgress)
        if(success) success = await this.props.cleanResourceCache(id, "image", onProgress)


        this.setState({
            uploading: false,
        })
    }



    render(){
        const {resource} = this.props
        const uploadTime = resource.timestamp && (new Date(resource.timestamp));
        return <div className="Image-resource">
            <h4>Image Information</h4>
            <Table>
                <tbody>
                    <tr>
                        <td>ID</td>
                        <td> {resource.id} </td>
                    </tr>
                    <tr>
                        <td>Name</td>
                        <td> {resource.name} </td>
                    </tr>
                    <tr>
                        <td>Extension</td>
                        <td> {resource.ext} </td>
                    </tr>
                    <tr>
                        <td>Type</td>
                        <td> {resource.type} </td>
                    </tr>
                    <tr>
                        <td>Size</td>
                        <td> {resource.size} Bytes </td>
                    </tr>
                    <tr>
                        <td>Uploaded on</td>
                        <td> {uploadTime && uploadTime.toLocaleString()} </td>
                    </tr>
                </tbody>
            </Table>


            <div style={{textAlign: "center"}}>
              
                <div>
                    <Form.File 
                        name="thumbnail"
                        accept=".jpg, .png" 
                        value={this.state.name}
                        onChange={this.handleChange}
                    />
                     {
                        this.state.uploading ?
                        <span>{this.state.progress}% {this.state.msg}</span> 
                        : <Button onClick={this.handleUploadFile}>Upload</Button> 
                     } 
                </div>

                <div>
                    <img src={resource.signedUrl} alt="Image"/>
                </div>
                {/* <ResourcePreview url={resource.signedUrl}/> */}
            </div>    
        </div>
    }
}


// const ImageResource = ({ resource, getSignedLinkOfResource }) => {
//     console.log(resource)
//     const uploadTime = resource.timestamp && (new Date(resource.timestamp));
//     if(resource.signedUrl){
//         const expired = ( Number(QueryString.parse(resource.signedUrl).Expires)*1000 - Date.now() ) < 1000;
//         if(expired) getSignedLinkOfResource(resource.id);
//     }else{
//         getSignedLinkOfResource(resource.id);
//     }
//     return <div className="Image-resource">
//         <h4>Image Information</h4>
//         <Table>
//             <tbody>
//                 <tr>
//                     <td>ID</td>
//                     <td> {resource.id} </td>
//                 </tr>
//                 <tr>
//                     <td>Name</td>
//                     <td> {resource.name} </td>
//                 </tr>
//                 <tr>
//                     <td>Extension</td>
//                     <td> {resource.ext} </td>
//                 </tr>
//                 <tr>
//                     <td>Type</td>
//                     <td> {resource.type} </td>
//                 </tr>
//                 <tr>
//                     <td>Size</td>
//                     <td> {resource.size} Bytes </td>
//                 </tr>
//                 <tr>
//                     <td>Uploaded on</td>
//                     <td> {uploadTime && uploadTime.toLocaleString()} </td>
//                 </tr>
//             </tbody>
//         </Table>
//         <div style={{textAlign: "center"}}>
//             <div>
//                 <img src={resource.signedUrl} alt="Image"/>
//             </div>
//             {/* <ResourcePreview url={resource.signedUrl}/> */}
//         </div>    
//     </div>
// }

export default connect(mapStateToProps, mapDispatchToProps)(ImageResource);