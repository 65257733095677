import {Component} from 'react';
import {connect} from 'react-redux';
import LoginPage from '../containers/LoginPage';
import {checkValidityOfToken, login} from '../store/actions/user';

let lastCheckedAt = Date.now();

function mapStateToProps({appState}){
    return { login: {...appState.login}}
}

const mapDispatchToProps = { checkValidityOfToken, getToken: login }


class AuthenticatedComponent extends Component{
    
    renderComponent = () => {
        const token = window.localStorage.getItem("token")
       
        if(token && this.props.login.authenticated){
            
            const C = this.props.component

            return <C />
        }else{
            return <LoginPage/>
        }
    }
    
    render() {
        return this.renderComponent()
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthenticatedComponent)