class ResourceModel{

    static from(resource:ResourceModel){

        return new ResourceModel({_id: resource.id, ...resource})
    }

    id: string;
    type: string; 
    name: string; 
    _timestamp: number;
    active: boolean;

    size?: number;
    status?:string;
    ext?:string;

    // Single File URL
    signedUrl: string;

    // Video URL
    thumbnail: string;
    youtubeUrl: string;
    urls: Map<string, string>;

    p720: Boolean;
    p480: Boolean;
    p360: Boolean;
    p720url: string;
    p480url: string;
    p360url: string;
    p720size: number;
    p480size: number;
    p360size: number;
    p720status: string;
    p480status: string;
    p360status: string;


    data?: Array<string>;
    settings?: Map<string, any>;

    constructor(props: { id?: string, _id: string,
        type: string, name: string, _timestamp: number, active: boolean,
        size?: number, status?: string, ext?: string, 
        signedUrl: string, thumbnail: string, youtubeUrl: string, urls:Map<string, string>,
        p720:Boolean, p480:Boolean, p360:Boolean,
        p720url:string, p480url:string, p360url:string,
        p720size:number, p480size:number, p360size:number,
        p720status:string, p480status:string, p360status:string,
        data?: Array<string>, settings?: Map<string, any>
    }){
        this.id = props.id? props.id : props._id;
        this.type = props.type;
        this.name = props.name;
        this._timestamp = props._timestamp;
        this.active = props.active;

        this.size = props.size;
        this.status = props.status;
        this.ext = props.ext;

        this.signedUrl = props.signedUrl;
        this.status = props.status;

        this.thumbnail = props.thumbnail;
        this.youtubeUrl = props.youtubeUrl;
        this.urls = props.urls;

        this.p360 = props.p360;
        this.p360size = props.p360size;
        this.p360status = props.p360status;
        this.p360url = props.p360url;

        this.p480 = props.p480;
        this.p480size = props.p480size;
        this.p480status = props.p480status;
        this.p480url = props.p480url;

        this.p720 = props.p720;
        this.p720size = props.p720size;
        this.p720status = props.p720status;
        this.p720url = props.p720url;

        this.data = props.data;
        this.settings= props.settings;
    }
}

export default ResourceModel;