class LiveClass{
    id:           string;
    datetime:     Date;
    instructors:  Array<string>;
    mode:         string;
    location:     string;
    password:     string;
    platform:     string;
    protected:    boolean;
    title:        string;
    topic:        string;
    type:         string;
    url:          string;
    _cid:         string;
    _lid:         string;
    _timestamp:   Date;
    _modifiedBy:  string;
    _startedAt:   Date;
    _startedBy:   string;
    constructor(props: { _id: string,
        datetime:Date; instructors: Array<string>, mode: string, password: string, location: string,
    platform: string, protected: boolean, title: string, topic: string,
    type: string,url: string,_cid: string;_lid: string,_timestamp: Date,
    _modifiedBy: string,_startedAt: Date,_startedBy: string,
    }){
        this.id = props._id;
        this.datetime = props.datetime;
        this.instructors = props.instructors;
        this.mode = props.mode;
        this.location = props.location;
        this.password = props.password;
        this.protected = props.protected;
        this.platform = props.platform;
        this.title = props.title;
        this.topic = props.topic;
        this.type = props.type;
        this.url = props.url;
        this._cid = props._cid;
        this._lid = props._lid;
        this._timestamp = props._timestamp;
        this._modifiedBy = props._modifiedBy;
        this._startedAt = props._startedAt;
        this._startedBy = props._startedBy;
    }
}

export default LiveClass;