import React from 'react';
import QueryString from "query-string";

import { Button, Col, Form, Row, Table } from 'react-bootstrap';
import { connect } from 'react-redux';


import CourseModel from '../../models/course'
import { api } from '../../services/apiService';
import {
    getCourseById, 
    putCourseMetadata, publishCourse
} from '../../store/actions/course';
import { getResource, getSignedLinkOfResource } from '../../store/actions/resource';
import ResourceUpload from '../resource/ResourceUpload';

import CourseContent from './CourseContent';
import NewCourseAssetModal from './NewCourseAssetModal';
import ResourcePreview from '../../components/resourcePreview';
import CourseBanner from './CourseBanner';



function mapStateToProps({course, resource}){
    const { courses, levels, weeks, days, assets } = course
    return {
        courses, levels, weeks, days, assets, resource
    }
}

const mapDispatchToProps = {
    
    getCourseById, putCourseMetadata, publishCourse, getResource, getSignedLinkOfResource
    
}

class CourseEdit extends React.Component{

    state = {
        description: "",
        cost: 0,
        highlights: [],
        prerequisite: [],
        newHighlight: "",
        newPrerequisite: "",
        show_banner_form: false,
        show_intro_form: false
    }

    get course(){
        const {id} = this.props.match.params
        const course = this.props.courses[id]
        return !course ? null : CourseModel.from(course)
    }

    async componentDidMount(){
        if(!this.course) await this.props.getCourseById(this.props.match.params.id);
        const course  = this.course;
        this.setState({
            description: course.description,
            cost: course.cost,
            highlights: [...course.highlights],
        })
    }

    handleAddHighlight = (e) => {
        e && e.preventDefault();
        const {highlights, newHighlight} = this.state;
        highlights.push(newHighlight)
        this.setState({
            highlights,
            newHighlight: ""
        })
    }

    handleAddPrerequisite = (e) => {
        e && e.preventDefault();
        const {prerequisite, newPrerequisite} = this.state;
        prerequisite.push(newPrerequisite)
        this.setState({
            prerequisite,
            newPrerequisite: ""
        })
    }

    handleChange = (e) => {
        const {value, name} = e.target;
        this.setState({
            [name]: value
        })
    }

   

    

    handleChangeHighlight = (index, e) => {
        e && e.preventDefault()
        const {value} = e.target
        const {highlights} = this.state

        highlights[index] = value;
        this.setState({
            highlights
        })
    }

    handleChangePrerequisite = (index, e) => {
        e && e.preventDefault()
        const {value} = e.target
        const {prerequisite} = this.state

        prerequisite[index] = value;
        this.setState({
            prerequisite
        })
    }

    handleDeleteHighlight = (index, e) => {
        e && e.preventDefault()
        const {highlights} = this.state
        highlights.splice(index, 1)
        this.setState({
            highlights
        })
    }

    handleDeletePrerequisite = (index, e) => {
        e && e.preventDefault()
        const {prerequisite} = this.state
        prerequisite.splice(index, 1)
        this.setState({
            prerequisite
        })
    }

    handlePublishCourse = async(e) => {
        e && e.preventDefault()
        
        const success = await this.props.publishCourse(this.course.id);
        if(success) alert("Successfully published the course.")
        else alert("Some error occured")
    }
    
    handleSaveMetadata = async (e) => {
        e && e.preventDefault();
        const { description, highlights, cost, prerequisite } = this.state
        const course = this.course;
        course.description = description;
        course.highlights = highlights;
        course.prerequisite = prerequisite;
        course.cost = cost;
        const success = await this.props.putCourseMetadata(course);
        if(success) alert("Successfully saved the course metadata.")
        else alert("Some error occured")
    }
    

    handleToggleBannerForm = (e) => {
        e && e.preventDefault()
        this.setState({
            show_banner_form: !this.state.show_banner_form
        })
    }

    handleToggleIntroForm = (e) => {
        e && e.preventDefault()
        this.setState({
            show_intro_form: !this.state.show_intro_form
        })
    }

    

    // !=======================================================================================================
    // !=======================================================================================================
    // !=======================================================================================================
    // !=======================================================================================================
    // !=======================================================================================================

    renderHighlights =() => {
        const {highlights} = this.state
        return highlights.map((value, i) => {
            return <tr key={i}>
                <td>{i+1}</td>
                <td>
                    <Form.Control
                        name="newHighlight" 
                        value={value}
                        onChange={this.handleChangeHighlight.bind(this, i)}
                    />
                </td>
                <td>
                    <Button onClick={this.handleDeleteHighlight.bind(this, i)}>Remove</Button>
                </td>
            </tr>
        })
    }

    renderPrerequisite =() => {
        const {prerequisite} = this.state
        return prerequisite.map((value, i) => {
            return <tr key={i}>
                <td>{i+1}</td>
                <td>
                    <Form.Control
                        name="newPrerequisite" 
                        value={value}
                        onChange={this.handleChangePrerequisite.bind(this, i)}
                    />
                </td>
                <td>
                    <Button onClick={this.handleDeletePrerequisite.bind(this, i)}>Remove</Button>
                </td>
            </tr>
        })
    }

    renderNewHighlightField = () => {
        const {newHighlight} = this.state
        return <tr>
            <td></td>
            <td>
                <Form.Control
                    name="newHighlight" 
                    value={newHighlight}
                    onChange={this.handleChange}
                />
            </td>
            <td>
                <Button onClick={this.handleAddHighlight}>Add</Button>
            </td>
        </tr>;
    }

    renderNewPrerequisiteField = () => {
        const {newPrerequisite} = this.state
        return <tr>
            <td></td>
            <td>
                <Form.Control
                    name="newPrerequisite" 
                    value={newPrerequisite}
                    onChange={this.handleChange}
                />
            </td>
            <td>
                <Button onClick={this.handleAddPrerequisite}>Add</Button>
            </td>
        </tr>;
    }


    

    
    

    render(){
        const course =  this.course
        if( !course ) return <div>Loading</div>
        const {description, cost} = this.state
        return <div className="course">
            <h1>{course.title}
                <Button className="inline" onClick={this.handleSaveMetadata} >
                    Save
                </Button>
                <Button className="inline" onClick={this.handlePublishCourse} >
                    Publish
                </Button>
            </h1>
            <h5>Last Published on : {course.published_on}</h5>
            <div>
                
                <Form.Group>
                    <Form.Row>
                        <Col lg="8" >
                            <Form.Label><h5>Description</h5></Form.Label>
                            <Form.Control
                                as="textarea"
                                name="description"
                                value={description}
                                onChange={this.handleChange}
                            />
                        </Col>
                        <Col>
                            <Form.Label><h5>Cost ( INR )</h5></Form.Label>
                            <Form.Control className="inline"
                                type="number"
                                name="cost"
                                value={cost}
                                onChange={this.handleChange}
                            />
                        </Col>
                    </Form.Row>
                </Form.Group>

                <Row>
                    <Col lg="6" md="6">
                        <h5>Hightlights</h5>
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Text</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderHighlights()}
                                {this.renderNewHighlightField()}
                            </tbody>
                        </Table>
                    </Col>
                    <Col>
                    <h5>Prerequisites</h5>
                        <Table>
                            <thead>
                                <tr>
                                    <th>#</th>
                                    <th>Text</th>
                                    <th>Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.renderPrerequisite()}
                                {this.renderNewPrerequisiteField()}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </div>

            <div>
                {!!this.course && <CourseBanner course={this.course}/>}
            </div>

            <CourseContent courseId={course.id}/>

            

        </div>
    }

}

export default connect(mapStateToProps, mapDispatchToProps) (CourseEdit);