import LiveClass from "../../../models/liveClass"
import { api } from "../../../services/apiService"
import { mapArrayToMap } from "../../../services/utils"
import { CREATE_ONLINE_CLASS, GET_ONLINE_CLASS, GET_ONLINE_CLASS_LIST, 
    MODIFY_ONLINE_CLASS, PUBLISH_ONLINE_CLASS_LINK, REMOVE_ONLINE_CLASS } from "../../actionTypes"

export const create = (payload) => {
    return async dispatch => {
        const res = await api.post('/academy/online-class', payload)
        const data = res.data;
        if(data.success){
            dispatch({
                type: CREATE_ONLINE_CLASS,
                onlineClass: new LiveClass(data.onlineClass)
            })
        }
        return data.success
    }
}

export const getUpcomingAll = () => {
    return async dispatch => {
        const res = await api.get('/academy/online-class/list')
        const data = res.data;
        console.log(data)
        if(data.success){
            const list = data.list.map(l => new LiveClass(l))
            dispatch({
                type: GET_ONLINE_CLASS_LIST,
                list: mapArrayToMap(list)
            })
        }
    }
}

export const getById = (id) => {
    return async dispatch => {
        const res = await api.get('/academy/online-class/'+id)
        const data = res.data;
        console.log(data);
        if(data.success){
            
            dispatch({
                type: GET_ONLINE_CLASS,
                onlineClass: new LiveClass(data.onlineClass)
            })
        }
    }
}

export const modify = (id, onlineClass) => {
    return async dispatch => {
        // dispatch({
        //     type: MODIFY_ONLINE_CLASS
        // })
    }
}

export const publishLink = (id, url) => {
    return async dispatch => {
        // dispatch({
        //     type: PUBLISH_ONLINE_CLASS_LINK
        // })
    }
}

export const remove = (id) => {
    return async dispatch => {
        const res = await api.delete('/academy/online-class/'+id)
        const data = res.data;
        if(data.success){
            dispatch({
                type: REMOVE_ONLINE_CLASS,
                id
            })
        }
    }
}