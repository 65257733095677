import {Component, Fragment} from 'react';
import { connect } from 'react-redux';

import {ListItem} from '../../components/DataStructures'
import { TextInput } from '../../components/Inputs'
import { FlatButton } from '../../components/Inputs/Button';

import * as instructorActions from '../../store/actions/academy/instructor';
import * as onlineClassActions from '../../store/actions/academy/onlineClass';
import * as courseActions from '../../store/actions/course';


function mapStateToProps ({appState, instructor, course, onlineClass}){
    return {
        initialFetch: appState.initialFetch,
        instructors: instructor,
        courses: course.courses,
        courseLevels: course.levels,
        lectures: Object.values(onlineClass)
    }
}

const mapDispatchToProps = {
    loadCourses: courseActions.loadAllCourses,
    loadInsctructors: instructorActions.getList,
    loadUpComingLectures: onlineClassActions.getUpcomingAll,
    cancelLecture: onlineClassActions.remove
}

class OnlineClassList extends Component{

    componentDidMount(){
        if(!this.props.initialFetch.course) this.props.loadCourses();
        if(!this.props.initialFetch.instructor) this.props.loadInsctructors();
        if(!this.props.initialFetch.onlineClass) this.props.loadUpComingLectures();
    }


    handleCancel = (lecture, e) => {
        e.preventDefault()
        this.props.cancelLecture(lecture.id)
    }

    // ! ============== Render Related ========================================================
    getLectureTitle = (lecture) => {
        let title = ""
        if(lecture.mode === "subscription"){
            const course = this.props.courses[lecture._cid];
            if(course){
                title = course.title;
            }
            const level = this.props.courseLevels[lecture._lid];
            if(level){
                title=level.title;
            }
            
        }else{
            title = lecture.title
        }
        return title
    }

    renderOnlineMode = () => {}
    renderOfflineMode = () => {}

    render() {
        return <div className="list">
            
            {
                this.props.lectures.map(l => {
                    return <ListItem key={l.id}
                        leading={l.datetime}
                        title={this.getLectureTitle(l)}
                        subtitle={l.topic}
                        trailing="Instructor images"
                        actions={<Fragment>
                            <FlatButton onClick={this.handleCancel.bind(this, l)} >Cancel</FlatButton>
                        </Fragment>}
                    />
                })
            }
        </div>
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(OnlineClassList);