import React from 'react';
import { Sidebar, SidebarItem } from '../../components/Sidebar';
import InstructorList from './InstructorList';
import NewInstructorForm from './NewInstructorForm';
import ScheduleOnlineClass from './ScheduleOnlineClass';

class AcademyMain extends React.Component{
    render(){
        return <div className="academy-main" style={{display: "flex"}}>
            <Sidebar>
                <SidebarItem label="Schedule Online Class" />
            </Sidebar>
            <div style={{flex: 1}}>
                {/* <ScheduleOnlineClass/> */}
                {/* <NewInstructorForm/> */}
                <InstructorList/>
            </div>
        </div>
    }
}

export default AcademyMain;