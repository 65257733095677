import React from 'react';
import { Button } from 'react-bootstrap';
import ResourceUpload from './resource/ResourceUpload';
import ResourceList from './resource/ResourceList';

class Resource extends React.Component{
    state = {
        show: false
    }

    handleAddNewResourceClick = (e) => {
        if(e) e.preventDefault()
        this.setState({
            show: !this.state.show
        })
    }

    render(){
        return <div className="resource">
            <ResourceList onAddNewResource={this.handleAddNewResourceClick} />
            <ResourceUpload show={this.state.show}  
                onHide={this.handleAddNewResourceClick} 
                
            />
        </div>
    }
}

export default Resource;
