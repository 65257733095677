import React from 'react';
import { Spinner } from 'react-bootstrap';
import {connect} from "react-redux";
import { CourseDayModel, CourseWeekModel } from '../../models/course';

function mapStateToProps({course}){
    const {weeks, days} = course
    return {
        weeks, days
    };
}


const mapDispatchToProps = {}


// class CourseLevel extends React.Component{
const CourseWeek = ({id, weeks, days, activeId, onItemClick}) =>{
    if(!id) return <div className="course-week">Invalid ID</div>
    const week = weeks[id] && CourseWeekModel.from(weeks[id])
    return <div className=" course-week">
        {week.days.map( did => {
            const day = days[did] && CourseDayModel.from(days[did])
            const active = day && activeId === day.id;
            return <div key={did} className={`course-structure__item ${active? "course-structure__item--active" : "" }`}
                onClick={(e) => onItemClick(day.id, "day", e)}>
                {!day ? <Spinner animation="border"/> : day.title}
            </div>
        })}
    </div>
}
// }

export default connect(mapStateToProps, mapDispatchToProps)(CourseWeek);