import { CREATE_ONLINE_CLASS, GET_ONLINE_CLASS, GET_ONLINE_CLASS_LIST, MODIFY_ONLINE_CLASS, REMOVE_ONLINE_CLASS } from "../../actionTypes"



const initialOnlineClass = {}

const onlineClassReducer = (state = initialOnlineClass, action) => {
    switch(action.type){
        case CREATE_ONLINE_CLASS: {
            const {onlineClass} = action
            return {...state, [onlineClass._id]: onlineClass}
        }
        case GET_ONLINE_CLASS: {
            const {onlineClass} = action
            return {...state, [onlineClass._id]: onlineClass}
        }
        case GET_ONLINE_CLASS_LIST: {
            return {...state, ...action.list}
        }
        case MODIFY_ONLINE_CLASS: {
            const {onlineClass} = action
            return {state, [onlineClass._id]: onlineClass}
        }
        case REMOVE_ONLINE_CLASS: {
            delete state[action.id]
            return {...state}
        }
        default: return state
    }
}

export default onlineClassReducer