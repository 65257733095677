import Instructor from "../../../models/instructor";
import { api } from "../../../services/apiService"
import { mapArrayToMap } from "../../../services/utils";
import { CREATE_INSTRUCTOR, GET_INSTRUCTOR, GET_INSTRUCTOR_LIST, UPDATE_INSTRUCTOR } from "../../actionTypes"

export const addNew = (paylaod) => {
    return async dispatch => {
        const res = await api.post('/academy/instructor', paylaod);
        const data = res.data;
        if(data.success){
            const instructor =  new Instructor(data.instructor)
            dispatch({
                type: CREATE_INSTRUCTOR,
                instructor
            })
        }
        
    }
}

export const getList = () => {
    return async dispatch => {
        const res = await api.get('/academy/instructor/list')
        const data = res.data

        if(data.success){
            const list = data.list.map(instructor => new Instructor(instructor))

            dispatch({
                type: GET_INSTRUCTOR_LIST,
                list: mapArrayToMap(list)
            })
        }
        
    }
}

export const getById = () => {
    return async dispatch => {
        dispatch({
            type: GET_INSTRUCTOR
        })
    }
}

export const update = () => {
    return async dispatch => {
        dispatch({
            type: UPDATE_INSTRUCTOR
        })
    }
}

