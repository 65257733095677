


// Course Related
export const CREATE_COURSE = "CREATE_COURSE";
export const CREATE_LEVEL = "CREATE_LEVEL";
export const CREATE_WEEK = "CREATE_WEEK";

export const ADD_COURSE_LEVEL = "ADD_COURSE_LEVEL";
export const ADD_COURSE_WEEK = "ADD_COURSE_WEEK";
export const ADD_COURSE_DAY = "ADD_COURSE_DAY";
export const ADD_COURSE_DAY_ASSET = "ADD_COURSE_DAY_ASSET";

export const DELETE_COURSE = "DELETE_COURSE";

export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_LEVEL = "GET_COURSE_LEVEL";
export const GET_COURSE_WEEK = "GET_COURSE_WEEK";
export const GET_COURSE_DAY = "GET_COURSE_DAY";

export const LOAD_ALL_COURSES = "LOAD_ALL_COURSES";

export const UPDATE_COURSE_DAY = "UPDATE_COURSE_DAY";
export const UPDATE_COURSE = "UPDATE_COURSE";

// Instructor Related
export const CREATE_INSTRUCTOR = "CREATE_INSTRUCTOR";
export const GET_INSTRUCTOR_LIST = "GET_INSTRUCTOR_LIST";
export const GET_INSTRUCTOR = "GET_INSTRUCTOR";
export const UPDATE_INSTRUCTOR = "UPDATE_INSTRUCTOR";

// Online Class Realated
export const CREATE_ONLINE_CLASS = "CREATE_ONLINE_CLASS";
export const GET_ONLINE_CLASS = "GET_ONLINE_CLASS";
export const GET_ONLINE_CLASS_LIST = "GET_ONLINE_CLASS_LIST";
export const MODIFY_ONLINE_CLASS = "MODIFY_ONLINE_CLASS";
export const PUBLISH_ONLINE_CLASS_LINK = "PUBLISH_ONLINE_CLASS_LINK";
export const REMOVE_ONLINE_CLASS = "REMOVE_ONLINE_CLASS";


// Resource Related'
export const ADD_RESOURCE = "ADD_RESOURCE";
export const DELETE_RESOURCE = "DELETE_RESOURCE";
export const GET_RESOURCE = "GET_RESOURCE";
export const GET_RESOURCE_SIGNED_LINK = "GET_RESOURCE_SIGNED_LINK";
export const LOAD_RESOURCES = "LOAD_RESOURCES";

// User related
export const USER_LOGIN = "USER_LOGIN";
export const USER_LOGOUT = "USER_LOGOUT";
export const VERIFY_TOKEN = "VERIFY_TOKEN";
