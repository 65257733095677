import React from 'react';
import {connect} from 'react-redux';
import { Button, Col, Container, Form, Table, Row } from 'react-bootstrap';



import { putResourceMetadata, putResourceVideoThumbnail } from '../../store/actions/resource'; 

function mapStateToProps(){
    return {}
}

const mapDispatchToProps = {
    putResourceVideoThumbnail, putResourceMetadata
}


class YoutubeVideoResource extends React.Component{
    state = {
        name: {p720: "", p360: "", p480: ""},
        file: {p720: null, p360: null, p480: null},
        uploading: {p720: false, p360: false, p480: false},
        progress: { p360: 0, p720: 0, p480: 0, },
        msg: {p720: "", p360: "", p480: ""},
    }

    handleChange = (e) => {
        let {name, value, type} = e.target
        const stateChanges = {name: {...this.state.name}, file: {...this.state.file}}
        stateChanges.name[name] = value
        stateChanges.file[name] = e.target.files[0]
        this.setState(stateChanges, ()=>this.handleUploadFile(name))
    }

    

    handleUploadThumbnail = async(e) =>{

        const thumbnail = e.target.files[0]

        let fileData = new FormData()
        let ext = thumbnail.name.split(".")
        ext = ext[ext.length-1]
        fileData.append(
            "thumbnail",
            thumbnail,
            thumbnail.name
        )

        const id = this.props.resource.id
        
        await this.props.putResourceVideoThumbnail(id, fileData)
        console.log("Thumbnail Completed");

    }

    handleChangeName = async(e) =>{
        e.preventDefault()
        const name = prompt("Enter new Name", "")
        const id = this.props.resource.id
        if(name){
           await this.props.putResourceMetadata(id, {name})
           console.log("Resource Updated");
        }
    }

    handleChangeYoutubeLink = async(e) =>{
        e.preventDefault()
        const youtubeUrl = prompt("Enter video url", "")
        const id = this.props.resource.id
        if(youtubeUrl){
           await this.props.putResourceMetadata(id, {youtubeUrl})
           console.log("Resource Updated");
        }
    }

    renderThumbnail = () => {
        const {resource} = this.props

        return <Row>
            <Col>
                <h4>Thumbnail</h4>
                <Form.File 
                    name="thumbnail"
                    accept=".jpg, .png" 
                    value={""}
                    onChange={this.handleUploadThumbnail}
                />
            </Col>
            <Col>
                {
                    resource.thumbnail  && 
                    <div className="resource-preview">
                        <img src={resource.thumbnail} alt="thumbnail" srcset="" height="100%" width="100%"/>
                    </div>
                }
            </Col>
        </Row>
    }

   
    render(){
        const {resource} = this.props
        const uploadTime = resource.timestamp && (new Date(resource.timestamp));

        return <div className="video-resource">
        <h4>Youtube Video Information</h4>
        <Table>
            <tbody>
                <tr>
                    <td>ID</td>
                    <td> {resource.id} </td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td> {resource.name} <a href="#" onClick={this.handleChangeName}>change</a> </td>
                </tr>

                <tr>
                    <td>Youtube Link</td>
                    <td> {resource.youtubeUrl} <a href="#" onClick={this.handleChangeYoutubeLink}>change</a> </td>
                </tr>

                <tr>
                    <td>Type</td>
                    <td> {resource.type} </td>
                </tr>
                <tr>
                    <td>Last Modified on</td>
                    <td> {uploadTime && uploadTime.toLocaleString()} </td>
                </tr>
            </tbody>
        </Table>
        <Container>
            {this.renderThumbnail()}
        </Container>
        
    </div>
    }
}



export default connect(mapStateToProps, mapDispatchToProps)(YoutubeVideoResource);