import { GET_INSTRUCTOR_LIST, LOAD_RESOURCES, USER_LOGIN, VERIFY_TOKEN } from "../actionTypes";

const initialState = {
    login:{
        authenticated: false,
        token: null,
        username: "",
        password: ""
    },
    initialFetch: {
        course: false,
        resource: false,
        instructors: false,
        onlineClass: false
    }
}

const stateReducer = (state=initialState, action) => {
    switch(action.type){
        case GET_INSTRUCTOR_LIST: {
            const {initialFetch} = state
            return {...state, initialFetch: {...initialFetch, instructors: true}}
        }
        case LOAD_RESOURCES: {
            const {initialFetch} = state
            return {...state, initialFetch: {...initialFetch, resource: true}}
        }
        case USER_LOGIN:{
            const {username, password, token, authenticated} = action
            const login = {
                username, password, token, authenticated
            }
            return {...state, login}
        }
        case VERIFY_TOKEN: {
            const {valid} = action
            const login = {...state.login, authenticated: valid}
            return {...state, login}
        }
        default:{
            return state;
        }
    }
}

export default stateReducer