import React from 'react';
import { Table } from 'react-bootstrap';

import {connect} from "react-redux";
import { CourseAssetModel } from '../../models/course';
import { getSignedLinkOfResource } from '../../store/actions/resource';

function mapStateToProps({course, resource}){
    const {assets} = course
    return {
        assets, resource
    };
}


const mapDispatchToProps = {
    getSignedLinkOfResource
}

class CourseAsset extends React.Component{
    
    render(){
        const {id, assets, resource} = this.props
        const asset = assets[id] && CourseAssetModel.from(assets[id])
        const r = resource[asset.rid] ? resource[asset.rid] : {}
    
        return <div className="course-asset">
            <h3>Course Asset Details</h3>
            <div className="course-asset__content">
                <Table className="course-asset__info">
                    <tbody>
                        <tr>
                            <td>Id</td>
                            <td>{asset.id}</td>
                        </tr>
                        <tr>
                            <td>Title</td>
                            <td>{asset.title}</td>
                        </tr>
                        <tr>
                            <td>Type</td>
                            <td>{asset.type}</td>
                        </tr>
                        <tr>
                            <td>Resource Id</td>
                            <td>{r.id} {r.id && <a target="__blank" href={`/resource/${r.id}`}>view</a>}</td>
                            <td>{r.id} {r.id && <a target="__blank" href={`/resource/${r.id}`}>Change</a>}</td>
                        </tr>
                        <tr>
                            <td>Resource Name</td>
                            <td>{r.name}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
    
            {/* <Modal show={this.props.show} onHide={this.handleHideModal}>
                <Modal.Header closeButton>
                    <Modal.Title>New Course Asset</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="new-course-asset">
                        {   
                            !this.state.type ? this.renderSidelist() :
                            this.state.awaiting ? <div>Loading resources</div> :
                            <div className="new-course-asset__content">
                                <h2 className="new-course-asset__name">{this.state.heading}</h2>
                                
                                {this.renderForm()}
                            </div>
                        }
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleHideModal}>
                        Close
                    </Button>
                    <Button variant="primary" onClick={this.handleSubmitForm}>
                        Submit
                    </Button>
                </Modal.Footer>
            </Modal> */}
        </div>
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(CourseAsset)

