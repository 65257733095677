import { api } from "../../services/apiService"
import { USER_LOGIN, VERIFY_TOKEN } from "../actionTypes";
import jwtDecode from 'jwt-decode';

export const login = (username, password) => {
    return async dispatch => {
        const payload = {username, password}
        const res = await api.post('/user/login', payload);
        const data = res.data;
        if(data.success && data.login.authenticated){
            window.localStorage.setItem("token", data.login.token)
            dispatch({
                type: USER_LOGIN,
                username, password,
                authenticated: data.login.authenticated,
                token: data.login.token
            })
            return data.success
        }else{
            window.localStorage.removeItem("token")
            dispatch({
                type: USER_LOGIN,
                username: "", password: "",
                authenticated: false,
                token: null
            })
        }
    }
}

export const checkValidityOfToken = () => {
    return async dispatch => {
        const token = window.localStorage.getItem("token")
        if(!token){
            dispatch({
                type: USER_LOGIN,
                username: "", password: "",
                authenticated: false,
                token: null
            })
            return
        }
        const decoded = jwtDecode(token)
        if(decoded.exp * 1000 < Date.now()) return false;
        const res = await api.post('/user/token/check', {token})
        const data = res.data
        if(data.success){
            dispatch({
                type: VERIFY_TOKEN,
                valid: data.valid
            })
            return data.valid
        }
    }
}