import { ADD_COURSE_LEVEL, ADD_COURSE_WEEK, ADD_COURSE_DAY, ADD_COURSE_DAY_ASSET, UPDATE_COURSE} from "../actionTypes"
import { CREATE_COURSE, DELETE_COURSE } from "../actionTypes";
import { GET_COURSE, GET_COURSE_LEVEL, GET_COURSE_WEEK, GET_COURSE_DAY } from '../actionTypes';
import { LOAD_ALL_COURSES } from "../actionTypes"

import CourseModel, {CourseWeekModel, CourseLevelModel, CourseDayModel} from '../../models/course'


const initialState = {
    courses: {},
    levels: {},
    weeks: {},
    days:{},
    assets: {}
}


const courseReducer = (state=initialState, action) => {
    
    switch(action.type){
        case CREATE_COURSE:{
            const {course} = action
            return {...state, courses: {...state.courses, [course.id]: course}}
        }
        case ADD_COURSE_LEVEL:{
            const {level} = action
            const course = CourseModel.from(state.courses[level._cid])
            course.levels.push(level.id)
            return {...state,
                courses: {...state.courses, [course.id]: course},
                levels:{...state.levels, [level.id]: level}
            }
        }
        case ADD_COURSE_WEEK:{
            const {week} = action
            const level = CourseLevelModel.from(state.levels[week._lid])
            level.weeks.push(week.id)
            return {...state, 
                levels: {...state.levels, [level.id]: level},
                weeks: {...state.weeks, [week.id]: week}
            }
        }
        case ADD_COURSE_DAY:{
            const {day} = action
            const week = CourseWeekModel.from(state.weeks[day._wid])
            week.days.push(day.id)
            return {...state, 
                weeks: {...state.weeks, [week.id]: week},
                days: {...state.days, [day.id]: day}
            }
        }
        case ADD_COURSE_DAY_ASSET:{
            const {asset} = action
            const day = CourseDayModel.from(state.days[asset._did])
            day.assets.push(asset.id)
            return {...state, 
                days: {...state.days, [day.id]: day},
                assets: {...state.assets, [asset.id]: asset}
            }
        }

        case DELETE_COURSE: {
            delete state.courses[action.id]
            return {...state, course: {...state.courses}}
        }
        case GET_COURSE: {
            const {course, levels} = action
            return {
                ...state, 
                courses: { ...state.courses, [course.id]: course },
                levels: { ...state.levels, ...levels }
            }
        }
        case GET_COURSE_LEVEL: {
            const {level, weeks} = action
            return {
                ...state, 
                levels: { ...state.levels, [level.id]: level },
                weeks: { ...state.weeks, ...weeks }
            }
        }
        case GET_COURSE_WEEK: {
            const {week, days} = action
            return {
                ...state, 
                weeks: { ...state.weeks, [week.id]: week },
                days: { ...state.days, ...days }
            }
        }
        case GET_COURSE_DAY: {
            const {day, assets} = action
            return {
                ...state,
                days: {...state.days, [day.id]: day},
                assets: {...state.assets, ...assets}
            }
        }
        case LOAD_ALL_COURSES: {
            const {list} = action
            const courses = {}
            for(let i=0; i<list.length; i++) courses[list[i].id] = list[i]
            return {...state, courses: {...state.courses, ...courses}}
        }
        case UPDATE_COURSE:{
            const {course} = action
            return {
                ...state, 
                courses: { ...state.courses, [course.id]: course },
            }
        }
        default:
            return state;
    }
}

export default courseReducer