import React from 'react';
import PropTypes from 'prop-types';

class SearchInput extends React.Component {
    state = {
        value: {},
        show: false,
        searchText: "",
        result: []
    }

    handleSelect = (value, e) => {
        e && e.preventDefault();
        this.props.onSelect && this.props.onSelect(value);
        this.setState({value, show: false, searchText: value[this.props.keyName], result: []})
    }

    

    handleSearchValueChange = (e) => { 
        if(e){
            e.preventDefault();
            const {value} = e.target
            const result = this.props.options.map(item => {
                if(this.props.filter(value, item)) return item;
            })
            

            this.setState({
                show: !!value,
                searchText: value,
                result
            })
        }
    }

    renderOptions = () => {
        const {keyName} = this.props
        const {result} = this.state
        if(!result) return <div>No Options</div>;
        return result.map(v => {
            if(v) return <SearchOption key={v.id} label={v[keyName]} onClick={this.handleSelect.bind(this, v)}/>
        })
    }

    render(){
        const {label} = this.props;
        const {show, searchText} = this.state;
        return <div className="input search-input">
            <div className="input__label search-input__label">{label}</div>
            <div className="input__field search-input__field">
                <input type="text" value={searchText} onChange={this.handleSearchValueChange}/>
                {
                    show &&
                    <div className="search-input__list">
                        {this.renderOptions()}
                    </div>
                }
            </div>
        </div>
    }

}

SearchInput.propTypes = {
    label: PropTypes.any,
    options: PropTypes.array,
    filter: PropTypes.func
}

const SearchOption = ({label, onClick}) => {
    return <div className="search-input__option" onClick={onClick} >{label}</div>
} 

export default SearchInput