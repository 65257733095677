import React from 'react';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';

import { List, ListItem } from '../../components/DataStructures';
import { FlatButton, TextInput } from '../../components/Inputs';
import * as instructorActions from '../../store/actions/academy/instructor'

function mapStateToProps ({appState, instructor}){

    return {instructor, initialFetch: appState.initialFetch}
}

const mapDispatchToProps = {
    getList: instructorActions.getList
}


class InstructorList extends React.Component{
    state={
        searchText: ""
    }

    componentDidMount(){

        if(this.props.initialFetch.instructors === false) this.props.getList()
    }

    //!=================================================================================================================

    handleSearchInstructor = (e) => {
        e.preventDefault();
        this.setState({
            searchText: e.target.value
        })
    }




    //!=================================================================================================================

    renderList = () => {
        const {instructor} = this.props
        return Object.values(instructor).map(t => {

            const match = t.givenName.toLowerCase().indexOf(this.state.searchText.toLowerCase()) > -1
            if(!match) return null
            return <ListItem key={t.id}
                className="instructor"
                title = {
                    <table>
                        <tbody>
                            <tr>
                                <th>Name:</th>
                                <td>{t.name.first + t.name.last}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Email:</th>
                                <td>{t.email}</td>
                                <td></td>
                            </tr>
                            <tr>
                                <th>Phone:</th>
                                <td>{t.phoneNumber}</td>
                                <td></td>
                            </tr>
                        </tbody>
                    </table>
                }
                subtitle={
                    <FlatButton>Block</FlatButton>
                }
                trailing={
                    <div className="instructor__pic"> 
                        Image
                    </div>
                }
            />
        });
    } 

    render(){ 
        const {searchText} = this.state

        return <div className="instructor-list">
            <div className="instructor-list__actions">
                <TextInput
                    className="instructor-list__search"
                    label="Search Instructor"
                    onChange={this.handleSearchInstructor}
                    value={searchText}
                />
                <Link to="/academy/new/instructor"> Add new instructor </Link>
            </div>
            <List>
                {this.renderList()}
            </List>
        </div>
        
    }
}


export default connect(mapStateToProps, mapDispatchToProps)(InstructorList)