import React from 'react';
import { connect } from 'react-redux';

import { Table, Button, Form, DropdownButton, Dropdown, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { deleteResource, loadResources } from '../../store/actions/resource'

const mapStateToProps = ({resource, appState}) => {
    return {
        resource, 
        loaded: appState.initialFetch.resource
    }
}

const mapDispatchToProps = {
    deleteResource, loadResources,
    
}


class ResourceList extends React.Component{
    state = {
        list: [],
        selectedType: "video",
        selectedTitle: "Video",
        searchText:""
    }

    async componentDidMount(){
        if(!this.props.loaded) await this.props.loadResources();
    }

    handleChange = (e) => {
        e.preventDefault()
        const {type, name, value} = e.target;
        this.setState({
            [name]: value
        })
    }

    handleDeleteResource = (id, e) => {
        e.preventDefault()
        const yes = window.confirm("Do you want to delete this resource ?")
        if(yes){
            this.props.deleteResource(id)
        }
    }

    handleSelectType = (type, title, e) =>{
        e && e.preventDefault();
        this.setState({
            selectedType: type,
            selectedTitle: title
        })
    }

    // ! =============================================================================================
    // ! =============================================================================================
    // ! =============================================================================================
    // ! =============================================================================================

    renderResources = () => {
        const list = Object.values(this.props.resource)
        return list.map((r, i) => {
            
            if(r.type.indexOf(this.state.selectedType) === -1 ) return null;
            if (r.name.toLowerCase().indexOf(this.state.searchText.toLowerCase()) === -1) return null;
            return <tr key={r.id}>
                <td> {i+1} </td>
                <td> <Link to={`/resource/${r.id}`}> {r.name} </Link>  </td>
                <td> {r.type} </td>
                <td> {r.size} </td>
                <td> {r.status} </td>
                <td> <Button onClick={this.handleDeleteResource.bind(this, r.id)}>Delete</Button> </td>
            </tr>
        })
    }

    renderTypeList = () => {
        
        return <DropdownButton className="resource-list__type"  title={this.state.selectedTitle} >
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "video", "Videos")} >Videos</Dropdown.Item>
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "image", "Images")} >Images</Dropdown.Item>
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "pdf", "PDF")} >Pdf</Dropdown.Item>
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "method/abacus", "Abacus")} >Abacus</Dropdown.Item>
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "method/abacustonumber", "Abacus to Number")} >Abacus To Number</Dropdown.Item>
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "method/mentalabacus", "Mental Abacus")} >Mental Abacus</Dropdown.Item>
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "method/numbertoabacus", "Number to Abacus")} >Number to Abacus</Dropdown.Item>
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "method/fingers", "Fingers")} >Fingers</Dropdown.Item>
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "method/flashcard", "Flashcard")} >Flashcard</Dropdown.Item>
            <Dropdown.Item onSelect={this.handleSelectType.bind(this, "method/jumbledictation", "Jumble Dictation")} >Jumble Dictation</Dropdown.Item>
        </DropdownButton>
    }


    render(){
        console.log(this.props)
        return <div className="resource-list">
            <div className="resource__filter">

                {this.renderTypeList()}

                <Form.Control 
                    className="inline" 
                    placeholder="Search Resource" 
                    name="searchText" 
                    onChange={this.handleChange} 
                    value={this.state.searchText}
                />

                <Button onClick={this.props.onAddNewResource}> + New Resource</Button>

            </div>
            <Table>
                <thead>
                    <tr>
                        <th>Sl No.</th>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Size</th>
                        <th>Status</th>
                        <th>Actions</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderResources()}
                </tbody>
            </Table>
        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceList);