import { LOAD_RESOURCES, DELETE_RESOURCE } from "../actionTypes";
import { GET_RESOURCE, GET_RESOURCE_SIGNED_LINK } from "../actionTypes";


const initialResoruces = {}

const resourceReducer = (allResources = initialResoruces, action) => {
    switch(action.type){
        case DELETE_RESOURCE: {
            const {id} = action
            delete allResources[id]
            return {...allResources}
        } 
        case GET_RESOURCE:{
            console.log(action)
            const {resource} = action
            return {...allResources, [resource.id]: resource}
        }
        case GET_RESOURCE_SIGNED_LINK:{
            const {resource} = action
            return {...allResources, [resource.id]: resource}
        }
        case LOAD_RESOURCES:{
            const {resources} = action
            return {...allResources,  ...resources}
        }
        default:
            return allResources;
    }
}

export default resourceReducer;