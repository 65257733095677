import React from 'react';
import {connect} from 'react-redux';

import { Button, Col, Container, Form, ListGroup,  Modal,  Row, Table } from 'react-bootstrap';
import MethodSettings from '../../components/MethodSettings';

import ResourceModel from '../../models/resource';

import { putResourceMetadata } from '../../store/actions/resource'; 

function mapStateToProps(){
    return {}
}



const mapDispatchToProps = {
    putResourceMetadata
}

class NumberToAbacusResource extends React.Component{

    state={
        createdOn: "",
        disappearIn: 2,
        maxDigits: 4,
        minDigits: 1,
        mode: "manual",
        numbers: "",
        showModal: false,
        changes: [],
        data: []
    }

    componentDidMount(){
        const resource = ResourceModel.from(this.props.resource)
        const data = resource.data.map((expr,i) => ({id: i , expr}))
        const createdOn = new Date(resource.timestamp);
        const payload = {data, createdOn: createdOn.toLocaleString()}
        if(resource.settings["disappearIn"]) payload.disappearIn = resource.settings["disappearIn"]
        if(resource.settings["minDigits"]) payload.minDigits = resource.settings["minDigits"]
        if(resource.settings["maxDigits"]) payload.maxDigits = resource.settings["maxDigits"]
        this.setState(payload)
    }

    handleAddData = (e) => {
        e.preventDefault()
        const {changes, data, numbers} = this.state
        // TODO check the entered numbers are in correct format or not
        const id = Date.now()
        changes.push({
            id, status: "+"
        })
        data.push({
            id,
            expr: numbers,
        })
        this.setState({
            changes, data, numbers: "", showModal: false
        })
    }

    handleChange = (e) => {
        e.preventDefault()
        try{

            this.setState({
                numbers: e.target.value,
            })
        }catch(err){
            this.setState({
                numbers: e.target.value
            })
        }
    }

    handleDecrease=(step, name, e)=>{
        e.preventDefault()
        this.setState({
            [name]: this.state[name] - step
        })
    }

    handleDeleteData = (id, e) => {
        e.preventDefault()
        const i = this.state.changes.findIndex(v => v.id === id)
        if(i > -1){
            const ch = this.state.changes[i]
            if(ch.status === "+"){
                this.state.changes.splice(i, 1)
                this.state.data.splice(this.state.data.findIndex(v => v.id === id), 1)
                this.setState({})
                return
            }
        }else{
            const i = this.state.data.findIndex(v => v.id === id)
            const d = this.state.data.splice(i, 1)
            this.state.changes.push({id: d.id, status: "-"})
            this.setState({})
        }
    }

    

    handleIncrese=(step, name, e)=>{
        e.preventDefault()
        this.setState({
            [name]: this.state[name] + step
        })
    }

    handleSaveData = async (e) => {
        e.preventDefault();
        const {disappearIn, minDigits, maxDigits, data} = this.state
        const metadata = {
            settings: {disappearIn, minDigits, maxDigits},
            data: data.map(v => v.expr)
        }
        await this.props.putResourceMetadata(this.props.resource.id, metadata)
        alert("Saved...")
    }

    handleToggleModal = (e) => {
        e && e.preventDefault()
        this.setState({
            showModal: !this.state.showModal
        })
    }


    render(){
        return <div className="abacus-resource">
            <div className="abacus-reource__info">
                
                <h3>Number to Abacus Resource
                    <div className="abacus-resource__actions">
                        <Button className="abacus-resource__action-btn">Delete</Button>
                        <Button className="abacus-resource__action-btn" onClick={this.handleSaveData}>Save</Button>
                    </div>
                </h3>
                <Table>
                    <tbody>
                        <tr>
                            <td>Id</td>
                            <td>{this.props.resource.id}</td>
                        </tr>
                        <tr>
                            <td>Name</td>
                            <td>{this.props.resource.name}</td>
                        </tr>
                        <tr>
                            <td>Created On</td>
                            <td>{this.state.createdOn}</td>
                        </tr>
                    </tbody>
                </Table>
            </div>
            <Container>
                <Row>
                    <Col>
                        <div className="abacus-resource__settings">
                            <h3>Settings</h3>
                            <MethodSettings 
                                min={1} max={5} step={1}
                                label="Disappear In (seconds)" name="disappearIn"
                                value={this.state.disappearIn}
                                onDecrease={this.handleDecrease}
                                onIncrese={this.handleIncrese}
                            />
                            <MethodSettings 
                                min={1} max={this.state.maxDigits} step={1}
                                label="Min Digits" name="minDigits"
                                value={this.state.minDigits}
                                onDecrease={this.handleDecrease}
                                onIncrese={this.handleIncrese}
                            />
                            <MethodSettings 
                                min={this.state.minDigits} max={10}step={1}
                                label="Max Digits" name="maxDigits"
                                value={this.state.maxDigits}
                                onDecrease={this.handleDecrease}
                                onIncrese={this.handleIncrese}
                            />
                            
                        </div>
                    </Col>
                    <Col>
                        <div className="abacus-resource__data">
                            <h3>Data <Button onClick={this.handleToggleModal}>Add New Data</Button></h3> 
                            <ListGroup as="ol">
                                {
                                    this.state.data.map(v => {
                                        return <ListGroup.Item as="li" action key={v.id}>
                                            <div className="abacus-resource__value">
                                                {v.expr}
                                            </div>
                                            <div className="abacus-resource__actions">
                                                <Button className="abacus-resource__action-btn">Edit</Button>
                                                <Button className="abacus-resource__action-btn" onClick={this.handleDeleteData.bind(this, v.id)}>Delete</Button>
                                            </div>
                                        </ListGroup.Item>
                                    })
                                }
                            </ListGroup>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Modal show={this.state.showModal} onHide={this.handleToggleModal}>
                <Modal.Header>
                    Add new data
                </Modal.Header>
                <Modal.Body>
                    <Form.Group>
                        <Form.Label>Enter your expression</Form.Label>
                        <Form.Control 
                            onChange={this.handleChange}
                            value={this.state.numbers}
                            type="number"
                        />
                        <Form.Label>
                            Evaluation: Yet to be implemented
                        </Form.Label>
                    </Form.Group>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.handleToggleModal}>Cancel</Button>
                    <Button variant="primary" onClick={this.handleAddData}>Add</Button>
                </Modal.Footer>
            </Modal>

        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NumberToAbacusResource);