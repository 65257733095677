import React from 'react';
import { connect } from 'react-redux';

import { getResource, getSignedLinkOfResource } from '../../store/actions/resource';
import AbacusResource from './AbacusResource';
import AbacusToNumberResource from './AbacusToNumberResource';
import FlashcardResource from './FlashcardResource';
import JumbleDictationResource from './JumbleDictationResource';
import MentalAbacusResource from './MentalAbacusResource';
import VideoResource from './VideoResource';
import ImageResource from './ImageResource';
import NumberToAbacusResource from './NumberToAbacusResource';
import FingersResource from './FingersResource';
import YoutubeVideoResource from './YoutubeVideoResource';

function mapStateToProps({resource}){
    return {
        resource
    }
}
const mapDispatchToProps = {
    getResource, getSignedLinkOfResource
}

// class ResourceInfomormation extends React.Component{
const ResourceInfomormation = ({resource, match, ...props}) => {
    const id = match.params.id;
    if(!resource[id]) props.getResource(id)
    const r = resource[id] ? resource[id] : {}
    if(r.type && r.type.indexOf("video") === 0){
        if(r.type.indexOf("youtube") !== -1){
            return <div className="resource-information">
                <h2>Resource Information</h2>
                <YoutubeVideoResource
                    resource={r}
                />
            </div>
        }
        return <div className="resource-information">
            <h2>Resource Information</h2>
            <VideoResource 
                resource={r}
                getSignedLinkOfResource={props.getSignedLinkOfResource}
            />
        </div>
    }else if (r.type && r.type.indexOf("image") === 0){
        return <div className="resource-information">
            <h2>Resource Information</h2>
            <ImageResource 
                resource={r}
                getSignedLinkOfResource={props.getSignedLinkOfResource}
            />
        </div>
    }else if (r.type && r.type==="method/abacus"){
        return <div className="resource-information">
            <h2>Resource Information</h2>
            <AbacusResource 
                resource={r}
            />
        </div>
    }else if (r.type && r.type==="method/abacustonumber"){
        return <div className="resource-information">
            <h2>Resource Information</h2>
            <AbacusToNumberResource 
                resource={r}
            />
        </div>
    }else if (r.type && r.type==="method/numbertoabacus"){
        return <div className="resource-information">
            <h2>Resource Information</h2>
            <NumberToAbacusResource 
                resource={r}
            />
        </div>
    }else if (r.type && r.type==="method/flashcard"){
        return <div className="resource-information">
            <h2>Resource Information</h2>
            <FlashcardResource 
                resource={r}
            />
        </div>
    }else if (r.type && r.type==="method/jumbledictation"){
        return <div className="resource-information">
            <h2>Resource Information</h2>
            <JumbleDictationResource 
                resource={r}
            />
        </div>
    }else if (r.type && r.type==="method/mentalabacus"){
        return <div className="resource-information">
            <h2>Resource Information</h2>
            <MentalAbacusResource 
                resource={r}
            />
        </div>
    }else if (r.type && r.type==="method/fingers"){
        return <div className="resource-information">
            <h2>Resource Information</h2>
            <FingersResource 
                resource={r}
            />
        </div>
    }
    return <div>Cannot get the Resource type</div>
}
// }

export default connect(mapStateToProps, mapDispatchToProps)(ResourceInfomormation);