import React from 'react';
import {connect} from 'react-redux';


import { Modal, Button, Form } from 'react-bootstrap';
import SearchResult from '../../components/searchResult';

import { loadResources } from '../../store/actions/resource';


function mapStateToProps({resource, appState}) {
    return {
        resource,
        loaded: appState.initialFetch.resource
    }
}

const mapDispatchToProps = { loadResources }


class NewCourseAssetModal extends React.Component{
    state= {
        awaiting: false,
        heading: "",
        title: "",
        type: "",
        resource_id: "",
        searchString: "",
        changed: false,
        result: []
    }

    async componentDidMount(){
        if(!this.props.loaded) {
           this.setState({awaiting: true}) 
           await this.props.loadResources()
           this.setState({awaiting: false})
        }
        if(this.props.type) this.handleItemClick(this.props.type)
    }

    handleChange = (e) => {
        e && e.preventDefault()
        const {name, value} = e.target
        this.setState({
            [name]: value
        })
    }

    handleHideModal = (e) => {
        e && e.preventDefault()
        this.setState({
            awaiting: false,
            heading: this.props.type? this.state.heading : "",
            title: "",
            type: this.props.type ? this.props.type : "",
            resource_id: "",
            searchString: "",
            changed: false,
            result: []
        })
        this.props.onHide()
    }
    

    handleItemClick = (type, e) => {
        e && e.preventDefault()
        let {heading, title} = this.state
        if(type.indexOf("video") === 0) {heading = "Video Resoruce"; title="";}
        else if(type.indexOf("image") === 0) {heading = "Image Resource"; title=""}
        else if(type.indexOf("method") === 0){
            if(type === "method/abacus") {heading = "Abacus Resource"; title = "Abacus";}
            else if(type === "method/abacustonumber") {heading = "Abacus to Number Resource"; title = "Abacus to Number";}
            else if(type === "method/flashcard") {heading = "Flashcard Resource"; title="Flashcard";}
            else if(type === "method/fingers") {heading = "Fingers Resource"; title="Fingers";}
            else if(type === "method/jumbledictation") {heading = "Jumble Dictation Resource"; title="Jumble Dictation";}
            else if(type === "method/mentalabacus") {heading = "Mental Abacus Ressource"; title="Mental Abacus";}
            else if(type === "method/numbertoabacus") {heading = "Number to Abacus Ressource"; title="Number to Abacus";}
        }
        this.setState({type, heading, title})
    }


    handleSeachResource = (e) => {
        
        const {value} = e.target;
        const changed = value.length > 2;
        const result = !changed ? [] : Object.values(this.props.resource)
            .filter(r => {
                if(this.state.type.indexOf("method") === 0){
                    if(this.state.type === r.type){
                        return r.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
                    }
                }else{
                    console.log(r.type, this.state.type)
                    if(r.type.indexOf(this.state.type) === 0){
                        return r.name.toLowerCase().indexOf(value.toLowerCase()) !== -1
                    }
                }
                return false;
            }
            );
        console.log("=======================================================================================")
        this.setState({
            searchString: value,
            changed,
            result
        });
    }

    handleSelectResource = (index) =>{
        this.setState({
            resource_id: this.state.result[index].id,
            changed: false,
            searchString: this.props.resource[this.state.result[index].id].name
        })
    }

    handleSubmitForm = async (e) => {
        e && e.preventDefault()
        const {title, resource_id} = this.state
        let success = false
        if(this.props.async){
            this.setState({
                awaiting: true
            })
            success = await this.props.onSubmit(title, resource_id)
            this.setState({
                awaiting: true
            })
        }else{
            success = this.props.onSubmit(title, resource_id)
            success = true
        }
        if(success) this.handleHideModal()
    }

    // ====================================================================================================================
    // ====================================================================================================================
    // ! Render Methods ================================
    // ====================================================================================================================
    // ====================================================================================================================
    
    renderSidelist = () => {
        return <div className="resource-types">
            <div className="resource-types__item" onClick={this.handleItemClick.bind(this, "video")}>Video</div>
            {/* <div className="resource-types__item" onClick={this.handleItemClick.bind(this, "pdf")}>PDF</div> */}
            <div className="resource-types__item" onClick={this.handleItemClick.bind(this, "method/abacus")}>Abacus</div>
            <div className="resource-types__item" onClick={this.handleItemClick.bind(this, "method/abacustonumber")}>Abacus to Number</div>
            <div className="resource-types__item" onClick={this.handleItemClick.bind(this, "method/fingers")}>Fingers</div>
            <div className="resource-types__item" onClick={this.handleItemClick.bind(this, "method/flashcard")}>Flashcard</div>
            <div className="resource-types__item" onClick={this.handleItemClick.bind(this, "method/jumbledictation")}>Jumble Dictation</div>
            <div className="resource-types__item" onClick={this.handleItemClick.bind(this, "method/mentalabacus")}>Mental Abacus</div>
            <div className="resource-types__item" onClick={this.handleItemClick.bind(this, "method/numbertoabacus")}>Number To Abacus</div>
        </div>
    }

    renderResourceInformation=()=>{
        const resource = this.props.resource[this.state.resource_id]
        return <div className="new-course-asset__video">
            <div className="new-course-asset__info">
                <div>Resource Id: {resource.id}</div>
                <div>Resource Type: {resource.type}</div>
                <div>Resource name: {resource.name}</div>
            </div>
        </div>
    }

    

    renderForm = () => {
        return <Form>
            <Form.Group>
                <Form.Label>Title of the asset</Form.Label>
                <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                />
            </Form.Group>
            <Form.Group>
                <Form.Label>Search resource by name</Form.Label>
                <Form.Control
                    value={this.state.searchString}
                    onChange={this.handleSeachResource}
                />
                {   
                    this.state.changed &&
                    <div className="search-input">
                        <SearchResult
                            list ={this.state.result.map(v => v.name)} 
                            onClickItem={this.handleSelectResource}
                        />
                    </div>
                }
            </Form.Group>
            {this.state.resource_id && this.renderResourceInformation()}
        </Form>
    }


    render(){
        return <Modal show={this.props.show} onHide={this.handleHideModal}>
            <Modal.Header closeButton>
                <Modal.Title>New Course Asset</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className="new-course-asset">
                    {   
                        !this.state.type ? this.renderSidelist() :
                        this.state.awaiting ? <div>Loading resources</div> :
                        <div className="new-course-asset__content">
                            <h2 className="new-course-asset__name">{this.state.heading}</h2>
                            {/* <h4 className="new-course-asset__type">{this.state.type}</h4> */}
                            {this.renderForm()}
                        </div>
                    }
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={this.handleHideModal}>
                    Close
                </Button>
                <Button variant="primary" onClick={this.handleSubmitForm}>
                    Submit
                </Button>
            </Modal.Footer>
        </Modal>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NewCourseAssetModal)