import React from 'react';
import {connect} from 'react-redux';

import { DatePicker, FlatButton, RadioInput, RadioOption, SelectInput, SearchInput, TextInput, TimePicker } from '../../components/Inputs';
import { List, ListItem } from '../../components/DataStructures';
import * as instructorActions from '../../store/actions/academy/instructor';
import * as onlineClassActions from '../../store/actions/academy/onlineClass';
import * as courseActions from '../../store/actions/course';


function mapStateToProps ({appState, instructor, course}){
    return {
        initialFetch: appState.initialFetch,
        instructors: Object.values(instructor),
        courses: Object.values(course.courses),
        courseLevels: course.levels
    }
}

const mapDispatchToProps = {
    getInstructorList: instructorActions.getList,
    getCourseList: courseActions.loadAllCourses,
    loadCourseLevels: courseActions.getCourseById,
    scheduleLecture: onlineClassActions.create
}

class ScheduleOnlineClass extends React.Component{
    state = {
        courseAwaiting: true,
        selectedCourse: {levels: []},

        title: "",
        location: "",
        mode: "subscription", 
        platform: "online", 
        _cid: "", 
        _lid: "", 
        topic: "", 
        datetime: {
            date: "",
            time: ""
        },
        isProtected: "false", 
        password: "", 
        instructors: [],
    }

    componentDidMount(){
        if(!this.props.initialFetch.instructor) this.props.getInstructorList()
        if(!this.props.initialFetch.course) this.props.getCourseList()
    }

    handleAddInstructor = (v) => {
        const notFound = !this.state.instructors.find(item => item.id === v.id)
        if(notFound){

            this.setState({
                instructors: [...this.state.instructors, v]
            })
        }
    }

    handleRemoveInstructor = (id, e) =>{
        e.preventDefault()
        const index = this.state.instructors.indexOf(item => id === item.id)
        this.state.instructors.splice(index, 1)
        this.setState({
            instructors: this.state.instructors
        })
    }

    handleValueChange = (e) => {
        e.preventDefault()
        let {name, value, type} = e.target
        if(type === "date" || type=="time"){
            if(name === type){
                value = {...this.state.datetime, [name]: value}
            }
            name="datetime"
        }
        this.setState({
            [name]: value
        })
    }

    handleSelectCourse = (item) => {
        for(let id in item.levels){
            if(!this.props.courseLevels[id]){
                this.props.loadCourseLevels(item.id).then(() => this.setState({courseAwaiting: false}))
                this.setState({
                    courseAwaiting: true
                })
            }
        }
        this.setState({
            _cid: item.id,
            selectedCourse: item
        })
    }

    handleSelectLevel = (item) => {
        this.setState({
            _lid: item.id
        })
    }
    
    handleSubmit = async () => {
        const { courseAwaiting, selectedCourse , ...formData } = this.state
        const payload = {
            mode: formData.mode, 
            platform: formData.platform, 
            topic: formData.topic, 
            datetime: formData.datetime,
            isProtected: formData.isProtected === "true", 
            instructors: formData.instructors.map(t => t.id),
        }

        if(payload.platform === "offline"){
            payload.location = formData.location;
            payload.title = formData.title;
        }
        if(payload.mode === "subscription"){
            payload._cid = formData._cid;
            payload._lid = formData._lid;
        }

        if(payload.isProtected) payload.password = formData.password
        
        const success = await this.props.scheduleLecture(payload)
        if(success) {
            this.props.history.replace('/academy/online-class/list')
        }
    }

    render(){

        const modes = [
            {value: "subscription", label: "Subscription"},
            {value: "freetoall", label: "Free to all"},
        ]

        const platforms = [
            {value: "online", label: "Online"},
            {value: "offline", label: "Offline"},
        ]
        const protectedOptions = [
            {value: "true", label: "Yes"},
            {value: "false", label: "No"}
        ]   

        const {title, mode, platform, _cid, _lid, location, topic, datetime, isProtected, password, instructors} = this.state

        return <div className="schedule-online-class">
            <h3>Schedule Online Class</h3>
            <div className="online-class-form">
                <div className="online-class-form__left">
                    <div className="online-class-form__mode">
                        <RadioInput label="Mode"
                            name = "mode"
                            options = {modes}
                            value = {mode}
                            onChange={this.handleValueChange}
                        />
                    </div>
                    <div className="online-class-form__platform">
                        <RadioInput label="Platform"
                            name="platform"
                            options={platforms}
                            onChange={this.handleValueChange}
                            value={platform}
                        />
                    </div>

                    {
                        platform === "offline" &&
                        <div className="online-class-form__location">
                            <TextInput label="Location" name="location" value={location} onChange={this.handleValueChange} />
                        </div>
                    }

                    {
                        mode === "subscription" ? 
                        <div className="online-class-form__course" style={{display: "flex"}}>
                            <SelectInput label="Course" 
                                options={this.props.courses} 
                                keyName="title"
                                onSelect={this.handleSelectCourse}
                            />
                            {
                                !this.state.courseAwaiting && 
                                <SelectInput label="Level" 
                                    options={this.state.selectedCourse.levels.map(id => this.props.courseLevels[id])} 
                                    keyName="title"
                                    onSelect={this.handleSelectLevel}
                                />
                            }
                        </div>
                        :
                        <div className="online-class-form__title">
                            <TextInput label="Title" name="title" value={title} onChange={this.handleValueChange}/>
                        </div>
                    }
                    
                    
                    <div className="online-class-form__topic">
                        <TextInput label="Topic" name="topic" value={topic} onChange={this.handleValueChange}/>
                    </div>
                    
                    <div className="online-class-form__datetime">
                        <DatePicker label="Date" name="date" value={datetime.date} onChange={this.handleValueChange} />
                        <TimePicker label="Time" name="time" value={datetime.time} onChange={this.handleValueChange} />
                    </div>

                    <div className="online-class-form__protected">
                        <RadioInput label="Protected"
                            name="isProtected"
                            value={isProtected}
                            options={protectedOptions}
                            onChange={this.handleValueChange}
                        />
                    </div>
                    {
                        isProtected === "true" &&
                        <div className="online-class-form__password">
                            <TextInput label="Password" name="password" value={password} onChange={this.handleValueChange} />
                        </div>
                    }
                </div>
                
                
                <div className="online-class-form__left">
                    <div>
                        <SearchInput 
                            label="Instructor"
                            options = {this.props.instructors}
                            keyName="givenName"
                            filter = {(value, item) => item.givenName.toLowerCase().indexOf(value.toLowerCase()) > -1}
                            onSelect={this.handleAddInstructor}
                        />
                    </div>
                    
                    <List className="online-class-form__instrutors-list" heading="Selected Instructors">
                        <h3></h3>
                        {instructors.map(item => {
                            return <ListItem 
                                className = "online-class-form__instrutor"
                                title={item.givenName}
                                subtitle = {item.email}
                                actions = {[<FlatButton onClick={this.handleRemoveInstructor.bind(this, item.id)}>-</FlatButton>]}
                            />
                        })}
                    </List>

                    
                </div>
            </div>
            <div>
                <FlatButton>Cancel</FlatButton>
                <FlatButton onClick={this.handleSubmit}>Schedule</FlatButton>
            </div>
        </div>
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ScheduleOnlineClass);