export const List = ({className, children, heading}) => {
    className = className? `${className} list` : "list"

    return <div className="list">
        <div className="list__heading">{heading}</div>
        <div className="list__items">
            {children}
        </div>
    </div>
}

export const ListItem = ({className, header, leading, title, subtitle, trailing, footer, actions, children}) => {
    className = className? `${className} list-item` : "list-item"
    return <div className={className}>
        <div className="list-item__header">{header}</div>
        <div className="list-item__main">
            <div className="list-item__leading">{leading}</div>
            <div className="list-item__center">
                <div className="list-item__title">{title}</div>
                <div className="list-item__subtitle">{subtitle}</div>
            </div>
            <div className="list-item__trailing">{trailing}</div>
            <div className="list-item__actions">
                {actions}
            </div>
        </div>
        <div className="list-item__footer">{footer}</div>
        {children}
    </div>
}