import React, {useState} from 'react';
import { Spinner } from 'react-bootstrap';
import {connect} from "react-redux";
import { CourseLevelModel, CourseWeekModel } from '../../models/course';

function mapStateToProps({course}){
    const {levels, weeks} = course
    return {
        levels, weeks
    };
}

const mapDispatchToProps = {

}


// class CourseLevel extends React.Component{
const CourseLevel = ({id, levels, weeks, activeId, onItemClick}) =>{

    if(!id) return <div className="course-level">Invalid ID</div>
    const level = levels[id] && CourseLevelModel.from(levels[id])
    return <div className="course-level">
        {level.weeks.map(wid => {
            const week = weeks[wid] && CourseWeekModel.from(weeks[wid])
            const active = week && activeId === week.id;
            return <div key={wid} className={`course-structure__item ${active? "course-structure__item--active" : "" }`}
                onClick={(e) => onItemClick(week.id, "week", e)}>
                {!week? <Spinner animation="border"/> : week.title }
            </div>
        })}
    </div>
}
// }

export default connect(mapStateToProps, mapDispatchToProps)(CourseLevel);