class Instructor{
    static from(instructor: Instructor){
        return new Instructor(instructor.toJson());
    }
    id: string;
    name: {first: string, last: string};
    email: string;
    phoneNumber: string;
    phoneVerified: boolean;
    emailVerified: boolean;
    blocked: boolean;
    resetCode: string;
    resetPassword: boolean;
    _createdBy: string;
    _timestamp: Date;
    _loggedIn: Date;
    _loggedOut: Date;

    givenName: string;

    constructor(props: {_id: string, name: {first: string, last: string}, email: string, phoneNumber: string,
        emailVerified: boolean, phoneVerified: boolean, blocked: boolean, resetCode: string, resetPassword: boolean,
        _createdBy: string, _timestamp: Date, _loggedIn: Date, _loggedOut: Date
    }){
        this.id = props._id;
        this.name = props.name;
        this.email = props.email;
        this.phoneNumber = props.phoneNumber;
        this.emailVerified = props.emailVerified;
        this.phoneVerified = props.phoneVerified;
        this.blocked = props.blocked;
        this.resetCode = props.resetCode;
        this.resetPassword = props.resetPassword;
        this._createdBy = props._createdBy;
        this._timestamp = props._timestamp;
        this._loggedIn = props._loggedIn;
        this._loggedOut = props._loggedOut;

        this.givenName = this.name.first + " " + this.name.last;
    }

    toJson(){
        return { _id: this.id,
            name: {first: this.name.first, last: this.name.last}, email: this.email, phoneNumber: this.phoneNumber,
            emailVerified: this.emailVerified, phoneVerified: this.phoneVerified, blocked: this.blocked, 
            resetCode: this.resetCode, resetPassword: this.resetPassword,
            _createdBy: this._createdBy, _timestamp: this._timestamp, _loggedIn: this._loggedIn, _loggedOut: this._loggedOut
        }
    }
}

export default Instructor;