import React from 'react';

export default class SelectInput extends React.Component {
    state = {
        value: {},
        show: false,
    }

    handleSelect = (value, e) => {
        e && e.preventDefault();
        this.props.onSelect && this.props.onSelect(value);
        this.setState({value, show: false})
    }

    handleToggleShow = (e) => {
        e && e.preventDefault();
        this.setState({
            show: !this.state.show
        })
    }

    renderOptions = () => {
        const {keyName, options} = this.props
        if(!options) return <div>No Options</div>;
        return options.map(v => {
            return <SelectOption key={v._id} label={v[keyName]} onClick={this.handleSelect.bind(this, v)}/>
        })
    }

    render(){
        const {label, keyName} = this.props;
        const {value} = this.state;
        return <div className="input select-input">
            <div className="input__label select-input__label">{label}</div>
            <div className="input__field select-input__field" onClick={this.handleToggleShow}>
                <div className="select-input__value" onClick={this.handleToggleShow}>
                    {
                        value && value[keyName]
                    }    
                </div>
                <div className="select-input__marker" onClick={this.handleToggleShow}>
                    <div onClick={this.handleToggleShow}></div>
                </div>
            </div>
            {
                this.state.show &&
                <div className="select-input__list">
                    {this.renderOptions()}
                </div>
            }
        </div>
    }

}

const SelectOption = ({label, onClick}) => {
    return <div className="select-input__option" onClick={onClick} >{label}</div>
} 